import {Outlet} from 'react-router-dom';
import './App.css';
import {SelectionProvider} from './components/selectionContext/SelectionContext'
import { LanguageProvider} from './components/selectionLanguageContext';
import { MyBooleanProvider } from './MyBooleanContext';

function App() {
    return (
      <LanguageProvider>
    <SelectionProvider>
    <MyBooleanProvider>
    <div>
      <Outlet/>
    </div>
    </MyBooleanProvider>
    </SelectionProvider>
    </LanguageProvider>
  );
}

export default App;
