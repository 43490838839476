import './please-wait.css'
// import bg from '../../imgs/goodbye/goodbyeBackGround.svg'
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../components/selectionLanguageContext';
function PleaseWait() {
    const [Phrase , setPhrase] = useState("Your request is being processed")
    const {selectedLanguage} = useLanguage()
    return(
        <div className="please-wait">
            {/* <img src={bg} alt="" /> */}
            <p>{Phrase}</p>
            <div className="looping-circles">
                <span id="sp1" className="sp"></span>
                <span id="sp2" className="sp"></span>
                <span id="sp3" className="sp"></span>
                <span id="sp4" className="sp"></span>
            </div>
            { selectedLanguage.toLowerCase() === "ar" ? 
                <p className='plz-wait-p' style={{direction : "rtl"}}>الرجاء الانتظار <span className="dot dot1">. </span><span className='dot dot2'>. </span><span className='dot dot3'>.</span></p> : 
                <p className='plz-wait-p'>Please Wait <span className="dot dot1">. </span><span className='dot dot2'>. </span><span className='dot dot3'>.</span></p> 
            }
        </div>
    )
}
export default PleaseWait;