import { useNavigate } from 'react-router-dom';
import Convert from '../../components/Convert';
import { useLanguage } from '../../components/selectionLanguageContext';
import goodBye from '../../imgs/goodbye/goodbye.png'
import './goodbye.css'
function GoodBye() {
    const {selectedLanguage , updateLanguage} = useLanguage()
    const navigate = useNavigate();
    return(
        <div className={selectedLanguage.toLowerCase() === "ar" ? "goodbye-container ar" :"goodbye-container"}>
            <img src={goodBye} alt="" className='goodbye-img'/>
            <div className="goodbye-text">
                <h3><strong><Convert text={"Thank you"} language={selectedLanguage}/></strong><Convert text={" for your request"} language={selectedLanguage}/></h3>
                <h4><Convert text={"We will prepare your request immediately"} language={selectedLanguage}/></h4>
                <p className='p1'><Convert text={"We hope you enjoyed your ordering experience with our app"} language={selectedLanguage}/></p>
            </div>
        </div>
    )
}
export default GoodBye;