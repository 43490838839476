import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import SandClock1 from './SandClock';
import axios from 'axios';
import { ORDERSTATUS } from './constants/urls';
import Meal from './Meal';
import { Spinner } from 'react-bootstrap';
import Convert from './components/Convert';
import { useLanguage } from './components/selectionLanguageContext';

function Tracking() {
    const [show, setShow] = useState(false);
    const [text,setText] = useState("")
    const {selectedLanguage} = useLanguage()
    let token = window.localStorage.getItem("token")
    const fetchorderStatus = async () => {
        await axios.post(ORDERSTATUS , {}, {
            headers : {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            }
        }).then(res => {
          if(res.status === 200){
            setText(res.data.message)
            handleDisappear()
          }
        }).catch((error) => {
          console.log(error)
        })
    }
    function handleDisappear() {
        setTimeout(() => {
            setShow(false);
            setTimeout(() => {
                setText("")
            }, 500);
            // fetchorderStatus();
        }, 8000);
    }
    const [BooleanValue] = useState(localStorage.getItem("BooleanValue") === null ? true : JSON.parse(localStorage.getItem("BooleanValue")))
  const handleClose = () => setShow(false);
  const handleShow = () => {setShow(true);fetchorderStatus()};
  const [animationName ] = useState("sand-show")
  const [animationDelay ] = useState("3s")
    return (
    <div className={BooleanValue ? "d-none" : selectedLanguage.toLowerCase() === "ar" ? "ar" : ""}>
      <Button className='animation-button' onClick={handleShow} style={{
        position : "fixed",
        bottom : "50%",
        zIndex : "9999",
        width : "200px",
        height : "40px",
        backgroundColor : "#CDA34F",
        animationName : `${animationName}`,
        animationDelay : `${animationDelay}`,
        border : "none",
      }}>
        <SandClock1/>
        <span><Convert text={"Tracke your order here"} language={selectedLanguage}/></span>
      </Button>

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title><Convert text={"Follow Your Order"} language={selectedLanguage}/></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="text-tracking">
        {text === "" ?  <Spinner animation="grow" variant="warning" /> : <Convert text={text} language={selectedLanguage}/> }
          </div>
        <Meal/>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default Tracking;