import { useEffect, useState } from 'react';
import logo from '../../imgs/global/Logo.png';
import './loader.css'
import {useNavigate} from "react-router-dom";
import axios from 'axios';
import { RESERVATION } from '../../constants/urls';
import Losyro from './Group 1000001282.svg'

let token ;
function Loader() {
    const [getTableNumber] = useState(window.location.href.split("/"))
    localStorage.setItem("BooleanValue",true)
    const navigate = useNavigate();
    const getToken = async() => {
        const data = {
            table_token: "jWZonPN1FuFRe5BJUNBP",
            latitude: String(47.4943629),
            longitude: String(19.0588622),
        };
        await axios.post(`${RESERVATION}/${getTableNumber[getTableNumber.length - 1]}`, data)
        .then((response) => {
            if(response.status === 200){
                window.localStorage.setItem("token",response.data.data.token) ;
                navigate("/menu");
            }else {
            }
        })
        .catch((error) => {
            setTimeout(() => {
                getToken()
            }, 2000);
        });
        }
    function success(pos) {
        var crd = pos.coords;
        // console.log("Your current position is:");
        // console.log(`Latitude : ${crd.latitude}`);
        // console.log(`Longitude: ${crd.longitude}`);
        // console.log(`More or less ${crd.accuracy} meters.`);
    }
    function errors(err) {
        console.warn(`ERROR(${err.code}): ${err.message}`);
    }
    var options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
    };
    useEffect(() => {
        getToken()
        if (navigator.geolocation) {
            navigator.permissions
            .query({ name: "geolocation" })
            .then(function (result) {
                // console.log(result);
                if (result.state === "granted") {
                    navigator.geolocation.getCurrentPosition(success, errors, options);
                } else if (result.state === "prompt") {
                    navigator.geolocation.getCurrentPosition(success, errors, options);
                } else if (result.state === "denied") {
                //do nothing
                }
            });
        } else {
            console.log("Geolocation is not supported by this browser.");
        }
    }, []);
    return(
        <div className='page load-p'>
        <div className="loader">
            {/* <img className='clip' src={clip} alt="" /> */}
            <img className='intro' src={logo} alt="" />
            <img className='foot' src={Losyro} alt="" />
        </div>
        </div>
    )
}
export const accessToken = token ;
export default Loader;