import { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import Nav from "../../components/navBar/Nav";
import './pack.css'
import PackCard from "../../components/packages-cards/PackCard";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Error from "../error/Error";
import { Spinner } from "react-bootstrap";
import { useSelection } from "../../components/selectionContext/SelectionContext";
import { useLanguage } from "../../components/selectionLanguageContext";
import Convert from "../../components/Convert";
import { GetPackages } from "../../constants/urls";
import svgBadNet from '../../imgs/signal_wifi_bad_FILL0_wght400_GRAD0_opsz24.svg'
import SelectionComponent from "../../components/selectionContext/SelectionComponent";


function Packages() {
    const { selectedValue } = useSelection();
    const {selectedLanguage , updateLanguage} = useLanguage()
    let packToken = window.localStorage.getItem("token");
    const [myData , setMyData] = useState([])
    const [sNull,setSNull] = useState("");
    const [load ,setLoad] =useState(1)
    const [DataRecieved , setDataRecieved] = useState(true)
    const navigate = useNavigate();
    const FetchPackages = async () =>{
        setLoad(1)
        setDataRecieved(true)
        await axios.get(GetPackages,{
            headers : {
                Accept : "application/json",
                Authorization : "Bearer" + packToken,
                currency : selectedValue
            }
        })
        .then((res) => {
        if(res.status === 200) {
            setLoad(0)
            setMyData(res.data.data)
            
    }
}).catch(error => {
    console.log(error)
    setLoad(0)
    setDataRecieved(false)
})
    }
    useEffect(() => {
        FetchPackages()
},[selectedValue])
useEffect(()=>{
    const nullSearch = document.querySelector(".null");
        //search activation with filters
        let hidden = 0;
        const cradsContainer = document.querySelectorAll(".cards-menu-container .card-menu")
        //search activation with search input 
        const searchInput = document.querySelector(".search input");
        const searchIcons = document.querySelector(".search-icon");
        const ActiveSreachIcons = document.querySelector(".search-icon .active-icon");
        ActiveSreachIcons.onclick= () => {
            searchInput.value = "";
            for(let c = 0 ; c < cradsContainer.length ; c++){
                cradsContainer[c].classList.remove("d-none");
            }
            searchIcons.classList.remove("active-search");
            nullSearch.classList.add("d-none")
        }
        searchInput.onkeyup = ()=> {
            hidden= 0;
            setSNull(searchInput.value)
            if(searchInput.value !== "")
            searchIcons.classList.add("active-search");
            if(searchInput.value === "")
            searchIcons.classList.remove("active-search");
            let searchValue2 = (searchInput.value === "" ? "" : searchInput.value.toLowerCase());
            if(searchInput.value !== "") {
            for(let c = 0 ; c < cradsContainer.length ; c++){
                cradsContainer[c].classList.remove("d-none")
                let cardText = cradsContainer[c].innerText.toLowerCase();
                if(cardText.indexOf(searchValue2) !== -1){
                    hidden -= 1;
                    cradsContainer[c].classList.remove("d-none");
                    nullSearch.classList.add("none");
                }else {
                    hidden += 1;
                    cradsContainer[c].classList.add("d-none");
                }
                if(hidden === cradsContainer.length)
                nullSearch.classList.remove("d-none");
                else{
                nullSearch.classList.add("d-none");
                }
            }   
            }
        } 
        //add to local
        let initBtnLocalStorage = document.querySelectorAll(".clicker");
        let addToCart = document.querySelectorAll(".add-note");
        let cardPrice = document.querySelectorAll(".card-menu.pack .main-price-2");
        let cardTitle = document.querySelectorAll(".card-menu.pack .card-text h4");
        let cardDes = document.querySelectorAll(".card-menu.pack .card-text p");
        let cardAmount = document.querySelectorAll(".card-menu.pack .inc-dec-value");
        let cardImg = document.querySelectorAll(".card-menu.pack img");
        let noteFromMenu = document.querySelectorAll(".note-writer textarea");
        let submitMenu = document.querySelectorAll(".note-writer .submit");
        let increamentBtn = document.querySelectorAll(".inc-dec .inc");
        let decreamentBtn = document.querySelectorAll(".inc-dec .dec");
        let noteCancel = document.querySelectorAll(".note-writer .cancel");
        let noteExit = document.querySelectorAll(".exit-note");
        let packId = document.querySelectorAll(".id-pack")
        let packType =document.querySelectorAll(".card-type")
        const cardMyOrder = {
            id : "",
            price : "",
            title : "",
            description : "",
            amount : 1,
            note : "",
            type : "",
        }
        for(let f =  0 ; f < addToCart.length ; f++) {
            initBtnLocalStorage[f].onclick = () => {
                cardMyOrder.pic = cardImg[f].src;
                cardMyOrder.id = packId[f].innerText;
                cardMyOrder.title = cardTitle[f].innerText;
                cardMyOrder.amount = 1;
                // cardMyOrder.description = cardDes[f].innerText;
                cardMyOrder.price = cardPrice[f].innerText.replace("$","");
                cardMyOrder.note = noteFromMenu[f].value;
                cardMyOrder.type = packType[f].innerText;
                window.localStorage.setItem(`pack${packId[f].innerText}`,JSON.stringify(cardMyOrder));
            }
            submitMenu[f].onclick = ()=> {
                cardMyOrder.pic = cardImg[f].src;
                cardMyOrder.id = packId[f].innerText;
                cardMyOrder.title = cardTitle[f].innerText;
                cardMyOrder.amount = parseInt(cardAmount[f].innerText)+1;
                // cardMyOrder.description = cardDes[f].innerText;
                    cardMyOrder.price = cardPrice[f].innerText.replace("$","");
                    cardMyOrder.note = noteFromMenu[f].value;
                    cardMyOrder.type = packType[f].innerText;
                    window.localStorage.setItem(`pack${packId[f].innerText}`,JSON.stringify(cardMyOrder));
            }
            increamentBtn[f].onclick = ()=> {
                cardMyOrder.pic = cardImg[f].src;
                cardMyOrder.id = packId[f].innerText;
                cardMyOrder.title = cardTitle[f].innerText;
                cardMyOrder.amount = parseInt(cardAmount[f].innerText)+1;
                // cardMyOrder.description = cardDes[f].innerText;
                cardMyOrder.price = cardPrice[f].innerText.replace("$","");
                cardMyOrder.note = noteFromMenu[f].value;
                cardMyOrder.type = packType[f].innerText;
                window.localStorage.setItem(`pack${packId[f].innerText}`,JSON.stringify(cardMyOrder));
            }
            decreamentBtn[f].onclick = ()=> {
                if(cardAmount[f].innerText > 1){
                    cardMyOrder.pic = cardImg[f].src;
                    cardMyOrder.id = packId[f].innerText;
                    cardMyOrder.title = cardTitle[f].innerText;
                    cardMyOrder.amount = parseInt(cardAmount[f].innerText) - 1;
                    // cardMyOrder.description = cardDes[f].innerText;
                cardMyOrder.price = cardPrice[f].innerText.replace("$","");
                cardMyOrder.note = noteFromMenu[f].value;
                cardMyOrder.type = packType[f].innerText;
                window.localStorage.setItem(`pack${packId[f].innerText}`,JSON.stringify(cardMyOrder));
            }else {
                window.localStorage.removeItem(`pack${packId[f].innerText}`);
            }
            }
            noteCancel[f].onclick = () => {
                noteFromMenu[f].value = JSON.parse(window.localStorage[f]).note;
            }
            noteExit[f].onclick = () => {
                if (window.localStorage[f] !== undefined) {
                    noteFromMenu[f].value = JSON.parse(window.localStorage[f]).note;
                } else {
                    noteFromMenu[f].value = "";
                }
            }
        }
},[myData])
useEffect(()=>{
    window.scroll(0, 0);
},[])
return(
        <>
        {packToken === null ? (<Error/>) : (
            <>
            <Header/>
            <div className={selectedLanguage.toLowerCase()==="ar" ? "page ar" : "page"}>
            {/* <SelectionComponent /> */}
            <div className="arrow-back-home" onClick={() => navigate("/home")}>
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="14" viewBox="0 0 9 14" fill="none">
                <path d="M7.99988 1L1.99988 7L7.99988 13" stroke="url(#paint0_linear_2_783)" strokeWidth="2" strokeLinecap="round"/>
                <defs>
                <linearGradient id="paint0_linear_2_783" x1="7.99988" y1="7" x2="1.99988" y2="7" gradientUnits="userSpaceOnUse">
                <stop stopColor="#CA9D4A"/>
                <stop offset="1" stopColor="#E0DA97"/>
                </linearGradient>
                </defs>
            </svg>
            </div>
            <div className="menu-container packages">
                <h2><Convert text={"Packages"} language={selectedLanguage}/></h2>
                <div className="search">
                    <input type="text" placeholder="Search"/>
                    <div className="search-icon pack">
                        <div className="normal-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M15 15L12.2186 12.0575C13.3249 10.9116 13.9738 9.40054 14.0432 7.80917C14.1125 6.2178 13.5973 4.65604 12.5949 3.41825C11.5924 2.18045 10.1718 1.35213 8.60087 1.08939C7.02994 0.826658 5.41717 1.14766 4.06653 1.9919C2.7159 2.83614 1.72069 4.14531 1.26849 5.67264C0.816292 7.19997 0.938335 8.83997 1.61162 10.2835C2.2849 11.7271 3.46291 12.8745 4.92362 13.5095C6.38434 14.1445 8.02686 14.2232 9.54159 13.7308" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        </div>
                        <div className="active-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M9.25825 1.30333L6.6066 3.95498L3.95495 1.30333C3.22243 0.570809 2.03549 0.57114 1.3033 1.30333C0.571114 2.03551 0.570782 3.22246 1.3033 3.95498L3.95495 6.60663L1.3033 9.25828C0.571114 9.99047 0.570782 11.1774 1.3033 11.9099C2.03582 12.6424 3.22276 12.6421 3.95495 11.9099L6.6066 9.25828L9.25825 11.9099C9.99077 12.6424 11.1777 12.6421 11.9099 11.9099C12.6421 11.1777 12.6424 9.9908 11.9099 9.25828L9.25825 6.60663L11.9099 3.95498C12.6421 3.22279 12.6424 2.03585 11.9099 1.30333C11.1774 0.570809 9.99044 0.57114 9.25825 1.30333Z" fill="url(#paint0_linear_25_991)"/>
                            <defs>
                            <linearGradient id="paint0_linear_25_991" x1="1.67677" y1="1.6768" x2="11.9099" y2="11.9099" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CDA34F"/>
                            <stop offset="1" stopColor="#A77C27"/>
                            </linearGradient>
                            </defs>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="cards-menu-container pack-cards position-relative">
                    {load ? (<Spinner animation="border" variant="warning" className="spinner"/>) : DataRecieved ? myData.map((item , i) => {
                        return(
                            <PackCard data={item} key={i}/>
                            )
                        }) : <div style={{
                            position : "fixed",
                            color : "white",
                            left : "50%",
                            top : "55%",
                            transform : "translateX(-50%) translateY(-50%)",
                            display : "flex",
                            flexDirection : "column",
                            alignItems : "center"
                        }}>
                            <img style={{width : "70px" , height : "70px"}} src={svgBadNet} alt="" />
                            <span style={{
                                display :"block",
                                margin : "10px 0",
                                marginBottom : "40px",
                                fontSize : "22px",
                                fontWeight : "600"
                            }}>Poor Connection</span>
                            <p onClick={()=>{FetchPackages()}} className="bad-network" style={{fontSize : "20px", color : "black" , background : "#CDA34F", padding : "5px 15px" , borderRadius : "10px" , fontWeight : "500" , cursor : "pointer"}}>Retry 
                            <svg style={{marginLeft : "10px"}} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path fill="black" d="M463.5 224H472c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2L413.4 96.6c-87.6-86.5-228.7-86.2-315.8 1c-87.5 87.5-87.5 229.3 0 316.8s229.3 87.5 316.8 0c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0c-62.5 62.5-163.8 62.5-226.3 0s-62.5-163.8 0-226.3c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H463.5z"/></svg>
                            </p>
                            </div>}
                </div>
                        <div className="null text-center text-white fs-6 d-none">No item matching “{sNull}”</div>
            </div>
            <Nav data={"menu-nav"}/>
        </div>
        </>
        )}
        </>
    )
}
export default Packages ;