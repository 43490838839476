import { Link } from "react-router-dom";
import Header from "../../components/header/Header";
import Nav from "../../components/navBar/Nav";
import Error from "../error/Error";
import TableCard from '../../components/table-cards/TableCard'
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import './Table.css'
import Convert from "../../components/Convert";
import { useLanguage } from "../../components/selectionLanguageContext";
import { useSelection } from "../../components/selectionContext/SelectionContext";
import { GetTableOrder } from "../../constants/urls";
import svgBadNet from '../../imgs/signal_wifi_bad_FILL0_wght400_GRAD0_opsz24.svg'

function Tabel() {
    const [selectedCurrency] = useState(localStorage.getItem("selectedCurrency"))
    const { selectedValue } = useSelection();
    const {selectedLanguage} = useLanguage()
    const[load , setLoad] = useState(1)
    const [totalPrice,setTotalPrice] = useState(0);
    const [CardsArray , setCardsArray] = useState([]);
    let tableToken  = window.localStorage.getItem("token");
    const [DataRecieved , setDataRecieved] = useState(true)
    const FetchMyTableOrders = async() =>{
        setLoad(1)
        setDataRecieved(true)
        await axios.get(GetTableOrder,{
            headers : {
                Accept : "application/json",
                Authorization : "Bearer " + tableToken,
                currency : selectedValue
            }
        }).then((res) => {
            if(res.status === 200) {
                setLoad(0)
                const CardsSet = new Set();
                let dataArrayTableAll = res.data.data;
                let dataArrayTable = dataArrayTableAll[0];
                setTotalPrice(dataArrayTableAll[1])
                for (let i = 0; i < dataArrayTable.length; i++) {
                    dataArrayTable[i].items.forEach(element => {
                        element.type = "item"
                        CardsSet.add(element);
                    });
                    dataArrayTable[i].packages.forEach(element => {
                        element.type= "pack"
                        CardsSet.add(element);
                    });
                }
                setCardsArray([...CardsSet]); 
            }
        }).catch(error => {
            console.log(error)
            setLoad(0)
            setDataRecieved(false)
        })
    }
    useEffect(() => {
        FetchMyTableOrders()
    } , [selectedValue])
    useEffect(()=>{
        window.scroll(0, 0);
    },[])
    return(
        <>
        {tableToken === null ? (<Error/>) : (<>
        <Header/>
            <div className={selectedLanguage.toLowerCase() === "ar" ?  "page ar" : "page"}>
                <div className="total">
                    <span>{selectedLanguage.toLowerCase() === "ar" ? "فاتورة الطاولة":<Convert text={"Total Table"} language={selectedLanguage}/>}</span>
                    <span>{load ? (<Spinner animation="border" variant="dark"  className="spinner-black"/>) : parseFloat(totalPrice).toFixed(2)} {selectedCurrency.toLocaleLowerCase() === "usd" ? "$" : "€" }</span>
                </div>
                <div className="tabel-container my-order-container">
                <div className="head-order">
                <div className="my-order-btn head-order-btn"><Link to="/my-order"><strong>{selectedLanguage.toLowerCase() === "en" ? "My ": ""}</strong>{selectedLanguage.toLowerCase() === "en" ? " Orders":<Convert text={"My Orders"} language={selectedLanguage}/>}</Link></div>
                <div className="my-table-order-btn head-order-btn active-order"><strong>{selectedLanguage.toLowerCase() === "en" ? "Table": ""}</strong>{selectedLanguage.toLowerCase() === "en" ? " Orders":selectedLanguage.toLowerCase() === "ar" ? "طلبات الطاولة" :<Convert text={"Table Orders"} language={selectedLanguage}/>}</div>
                </div>
                <div className="cards-container position-relative">
                {load ? (<Spinner animation="border" variant="warning" className="spinner"/>) : DataRecieved ?  CardsArray.map((item , i) => {
                    return(
                            <TableCard data={item} key={i}/>
                            )
                        }) :
                        <div style={{
                            position : "fixed",
                            color : "white",
                            left : "50%",
                            top : "55%",
                            transform : "translateX(-50%) translateY(-50%)",
                            display : "flex",
                            flexDirection : "column",
                            alignItems : "center"
                        }}>
                            <img style={{width : "70px" , height : "70px"}} src={svgBadNet} alt="" />
                            <span style={{
                                display :"block",
                                margin : "10px 0",
                                marginBottom : "40px",
                                fontSize : "22px",
                                fontWeight : "600"
                            }}>Poor Connection</span>
                            <p onClick={()=>{FetchMyTableOrders()}} className="bad-network" style={{fontSize : "20px", color : "black" , background : "#CDA34F", padding : "5px 15px" , borderRadius : "10px" , fontWeight : "500" , cursor : "pointer"}}>Retry 
                            <svg style={{marginLeft : "10px"}} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path fill="black" d="M463.5 224H472c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2L413.4 96.6c-87.6-86.5-228.7-86.2-315.8 1c-87.5 87.5-87.5 229.3 0 316.8s229.3 87.5 316.8 0c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0c-62.5 62.5-163.8 62.5-226.3 0s-62.5-163.8 0-226.3c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H463.5z"/></svg>
                            </p>
                            </div> 
                        }
                </div>
                </div>
                <Nav/>
            </div>
        </>
        )}
        </>
    )
}
export default Tabel ;