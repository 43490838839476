import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Home from './pages/Home/Home';
import Loader from './pages/Load-pages/Loader';
import About from './pages/about-us/About';
import MyOrder from './pages/my-order/MyOrder';
import PleaseWait from './pages/please-wait/PleaseWait';
import Error from './pages/error/Error';
import Tabel from './pages/my-order-table/MyTableOrder';
import FullMenu from './pages/Menu/FullMenu';
import FullPackages from './pages/packages/FullPackages';
import FullTrending from './pages/trending/FullTrending';
import GoodBye from './pages/GoodBye/GoodBye';
import OrderSubmitted from './pages/please-wait/OrderSubmitted';
import ShowItem from './pages/showitem/ShowItem';
import ShowItemTreding from './pages/showItemTrending/ShowItemTrending';
import ShowPackage from './pages/showPackage/ShowPackage';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children : [
      {
      path:"/",
      element:<Error />
      },
      {
      path:"home",
      element:<Home />
      },
      {
      path:"menu",
      element:<FullMenu />
      },
      {
      path:"about",
      element:<About />
      },
      {
      path:"my-order",
      element:<MyOrder />
      },
      {
        path:"submitting",
        element:<PleaseWait/>
      },
      {
        path :"special-offers",
        element : <FullPackages/>
      },
      {
        path :"trending",
        element : <FullTrending/>
      },
      {
        path :"error",
        element : <Error/>
      },
      {
        path :"table-order",
        element : <Tabel/>
      },
      {
        path :"order-submitted",
        element : <OrderSubmitted/>
      },
      { 
        path :"GoodBye",
        element : <GoodBye/>
      },
      { 
        path :":number",
        element : <Loader/>
      },
      { 
        path :"item/:number",
        element : <ShowItem/>
      },
      { 
        path :"trended-item/:number",
        element : <ShowItemTreding/>
      },
      { 
        path :"package/:number",
        element : <ShowPackage/>
      },
      ]
  },
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);


reportWebVitals();
