import './error.css'
import error404People from '../../imgs/error/404 Error Page not Found with people connecting a plug-cuate 1.svg'
import Convert from '../../components/Convert';
import { useLanguage } from '../../components/selectionLanguageContext';
import { useNavigate } from 'react-router-dom';
function Error () {
    const {selectedLanguage , updateLanguage} = useLanguage()
    const navigate = useNavigate()
    return (
        <div className="error-container">
            <img src={error404People} alt="" />
            <p>{<Convert text={"Opps !! This Page is Note Found"} language={selectedLanguage}/> === null ? "Opps !! This Page is Note Found" : <Convert text={"Opps !! This Page is Note Found"} language={selectedLanguage}/> }</p>
            {/* <span onClick={() => {navigate("/")}}>Back To Home</span> */}
        </div>
    )
}
export default Error;