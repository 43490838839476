import { useEffect, useState } from 'react';
import './nav.css'
import { Link, NavLink } from 'react-router-dom';
import axios from 'axios';
import Convert from '../Convert';
import { useLanguage } from '../selectionLanguageContext';
import Tracking from '../../tracking';
import { Spinner } from 'react-bootstrap';
import { AskForBill, AskForHelp, AskForPrivacy } from '../../constants/urls';
function Nav() {
    let method; 
    const [billsepatrtlyLoad , setBillsepatrtlyLoad] = useState(false)
    const [activated , setActivated] = useState(false);
    const [activated1 , setActivated1] = useState(false);
    const [popContnet , setPopContent] = useState("");
    const {selectedLanguage , updateLanguage} = useLanguage()
    let tableToken = window.localStorage.getItem("token")
    // function removeActiveLi() {
    //     for(let j = 0 ; j < cashList.length ; j++) {
    //         cashList[j].classList.remove("active-cash");
    //     }
    // }
    function getPaymentMethod(text) {
        switch (text) {
            case "Pay Cash":
                return "Cash";
            case "Credit Card":
                return "Credit_Card";
            case "Pay Separately":
                return "Separately";
            case "Use COUPON":
                return "Coupon";
            default:
                return "No method selected";
        }
    }
    const [loaderWholeTable , setLoaderWholeTable] = useState(false)
    useEffect(() => {
        const cashList = document.querySelectorAll(".method-list li");
        const middleIcon = document.querySelector(".circle-icon2");
        const leftSide =document.querySelector(".left-side");
        const topSide =document.querySelector(".top-side");
        const rightSide =document.querySelector(".right-side");
        const popUp = document.querySelector(".pop-up-200")
        middleIcon.onclick = ()=> {
            middleIcon.classList.toggle("active-circle");
            leftSide.classList.toggle("left-side-active");
            rightSide.classList.toggle("right-side-active");
            topSide.classList.toggle("top-side-active");
        }
        const linksNav = document.querySelectorAll(".nav-container li");
        for(let u = 0 ; u < linksNav.length ; u++) {
            linksNav[u].onclick = () => {
                for(let m = 0 ; m < linksNav.length ; m++) {
                    linksNav[m].classList.remove("active-nav");
                }
                linksNav[u].classList.add("active-nav");
            }
        }
        const leaveThe = document.querySelector(".left-side")
        const question = document.querySelector(".top-side")
        const bill = document.querySelector("#submit-bill1")
        const bill1 = document.querySelector(".submit-bill")
        leaveThe.onclick = ()=>{
            if(middleIcon.classList.contains("active-circle")){
                setTimeout(() => {
                    axios.post(AskForPrivacy,{},{
                        headers : {
                        Accept : "application/json",
                        Authorization : "Bearer " + tableToken,
                        }
                    }).then(res => {
                        if(res.status === 200) {
                            setPopContent("We Will Leave You Alone 'Til You Ask For Help")
                            popUp.classList.remove("up");
                            setTimeout(() => {
                                popUp.classList.add("up")
                            }, 5000);
                        }
                    }).catch(error => {
                        // Handle errors
                        if (error.response) {
                          // The request was made and the server responded with a status code that falls out of the range of 2xx
                          console.log('Response status:', error.response.status);
                          console.log('Response data:', error.response.data);
                        } else if (error.request) {
                          // The request was made but no response was received
                          console.log('Request was made but no response received:', error.request);
                        } else {
                          // Something happened in setting up the request that triggered an Error
                          console.log('Error setting up the request:', error.message);
                        }
                        console.log('Error config:', error.config);
                      });
                }, 1000);
                }
            }
                question.onclick = ()=>{
                    axios.post(AskForHelp,{},{
                    headers : {
                        Accept : "application/json",
                        Authorization : "Bearer " + tableToken,
                    }
                }).then(res => {
                if(res.status === 200) {
                    setPopContent("The lounge clerk is on his way to you");
                    popUp.classList.remove("up");
                    setTimeout(() => {
                        popUp.classList.add("up")
                    }, 5000);
                }
            }).catch(error => {
                // Handle errors
                if (error.response) {
                  // The request was made and the server responded with a status code that falls out of the range of 2xx
                  console.log('Response status:', error.response.status);
                  console.log('Response data:', error.response.data);
                } else if (error.request) {
                  // The request was made but no response was received
                  console.log('Request was made but no response received:', error.request);
                } else {
                  // Something happened in setting up the request that triggered an Error
                  console.log('Error setting up the request:', error.message);
                }
                console.log('Error config:', error.config);
              });
        }
        cashList.forEach((item, j) => {
            item.onclick = () => {
                if (item.classList.contains("active-cash")) {
                    item.classList.toggle("active-cash");
                } else {
                    cashList.forEach(item => item.classList.remove("active-cash"));
                    item.classList.add("active-cash");
                    
                    method = getPaymentMethod(item.innerText);
                }
            };
        });
        bill.onclick = ()=>{
                    setBillsepatrtlyLoad(true)
                    axios.post(AskForBill,{
                        order_id : JSON.parse(window.localStorage.getItem("orderIds")),
                        payment_method : "Separately",
                    },{
                    headers : {
                        Accept : "application/json",
                        Authorization : "Bearer " + tableToken,
                    }
                }).then(res => {
                    if(res.status === 200) {
                        setBillsepatrtlyLoad(false)
                        setActivated(false)
                        setPopContent("Thanks For Visiting Our Resturant It Was A Great Pleasure , Have A Good Day ..");
                        popUp.classList.remove("up");
                        setTimeout(() => {
                            popUp.classList.add("up")
                            setTimeout(() => {
                                window.location.replace('/GoodBye')
                                window.localStorage.clear()
                            }, 1000);
                },5000)
                }})
                .catch(error => {
                // Handle error
                if (error.response) {
                    // The request was made and the server responded with a status code
                    setBillsepatrtlyLoad(false)
                    console.log('Response data:', error.response.data);
                    console.log('Response status:', error.response.status);
                    console.log('Response headers:', error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    setBillsepatrtlyLoad(false)
                    console.log('Request made but no response received:', error.request);
                } else {
                    setBillsepatrtlyLoad(false)
                    // Something else happened while setting up the request
                    console.log('Error during request setup:', error.message);
                }
            });
            }
        bill1.onclick = ()=>{
                    axios.post(AskForBill,{
                        order_id : [],
                        payment_method : method,
                    },{
                    headers : {
                        Accept : "application/json",
                        Authorization : "Bearer " + tableToken,
                    }
                }).then(res => {
                    if(res.status === 200) {
                        setLoaderWholeTable(false)
                        setActivated1(false)
                        setPopContent("Thanks For Visiting Our Resturant It Was A Great Pleasure , Have A Good Day ..");
                        popUp.classList.remove("up");
                    setTimeout(() => {
                        popUp.classList.add("up")
                        setTimeout(() => {
                            window.location.replace('/GoodBye')
                            window.localStorage.clear()
                        }, 1000);
                },5000)
                }})
                .catch(error => {
                // Handle error
                if (error.response) {
                    // The request was made and the server responded with a status code
                    setLoaderWholeTable(false)
                    console.log('Response data:', error.response.data);
                    console.log('Response status:', error.response.status);
                    console.log('Response headers:', error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    setLoaderWholeTable(false)
                    console.log('Request made but no response received:', error.request);
                } else {
                    // Something else happened while setting up the request
                    setLoaderWholeTable(false)
                    console.log('Error during request setup:', error.message);
                }
            });
            }
        },[])  
    return(
        <>
        <Tracking/>
        <div className="pop-up-200 up">
            <div className="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
                <g clipPath="url(#clip0_27_2203)">
                <path d="M15.4997 0C6.95276 0 0 6.9533 0 15.5C0 24.0467 6.95276 31 15.4997 31C24.0466 31 31 24.0467 31 15.5C31 6.9533 24.0466 0 15.4997 0ZM15.4997 28.9672C8.07395 28.9672 2.03282 22.9257 2.03282 15.5C2.03282 8.07418 8.07387 2.0329 15.4997 2.0329C22.9255 2.0329 28.9669 8.07426 28.9669 15.5C28.967 22.9257 22.9254 28.9672 15.4997 28.9672Z" fill="#03AC00"/>
                <path d="M21.1115 10.4258L13.1188 18.418L9.88793 15.1878C9.4909 14.7913 8.84772 14.791 8.45069 15.1881C8.0535 15.5853 8.0535 16.2285 8.45069 16.6255L12.4003 20.5746C12.5988 20.7728 12.8588 20.872 13.1188 20.872C13.3788 20.872 13.6395 20.7728 13.8379 20.5742C13.8386 20.5734 13.839 20.5725 13.84 20.5715L22.5486 11.863C22.9458 11.4662 22.9458 10.8225 22.5486 10.4257C22.1517 10.0287 21.508 10.0287 21.1115 10.4258Z" fill="#03AC00"/>
                </g>
                <defs>
                <clipPath id="clip0_27_2203">
                <rect width="31" height="31" fill="white"/>
                </clipPath>
                </defs>
            </svg>
            <p>{popContnet}</p>
            </div>
        </div>
        <div className="pop-up-bad"></div>
        <div className={selectedLanguage.toLowerCase() === "ar" ? "nav-container ar" : "nav-container"}>
            <ul className="list">
                <li id='home-nav' className='active-nav home-nav'>
                    <NavLink to="/home">
                <div className="icon-nav">
                <svg className='normal' xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
                    <path d="M11.9587 16.0393V11.236H8.04567V16.0393C8.04567 16.5677 7.60545 17 7.06741 17H4.13265C3.59461 17 3.1544 16.5677 3.1544 16.0393V9.31462H1.49137C1.04137 9.31462 0.826158 8.76704 1.16855 8.47884L9.34674 1.24497C9.71848 0.918343 10.2859 0.918343 10.6576 1.24497L18.8358 8.47884C19.1684 8.76704 18.963 9.31462 18.513 9.31462H16.8499V16.0393C16.8499 16.5677 16.4097 17 15.8717 17H12.9369C12.3989 17 11.9587 16.5677 11.9587 16.0393Z" stroke="white" strokeWidth="1.3"/>
                </svg>
                <svg className='onhover' xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
                    <path d="M10.9587 15.0393V10.236H7.04567V15.0393C7.04567 15.5677 6.60545 16 6.06741 16H3.13265C2.59461 16 2.1544 15.5677 2.1544 15.0393V8.31462H0.491371C0.0413742 8.31462 -0.173841 7.76704 0.168547 7.47884L8.34674 0.244971C8.71848 -0.0816572 9.28587 -0.0816572 9.6576 0.244971L17.8358 7.47884C18.1684 7.76704 17.963 8.31462 17.513 8.31462H15.8499V15.0393C15.8499 15.5677 15.4097 16 14.8717 16H11.9369C11.3989 16 10.9587 15.5677 10.9587 15.0393Z" fill="url(#paint0_linear_23_255)"/>
                    <defs>
                    <linearGradient id="paint0_linear_23_255" x1="9" y1="0" x2="9" y2="16" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#CA9D4A"/>
                    <stop offset="1" stopColor="#E0DA97"/>
                    </linearGradient>
                    </defs>
                </svg>
                </div>
                        <span>
                            {
                            selectedLanguage.toLowerCase() === "en" ?  "Home" : selectedLanguage.toLowerCase() === "ar" ? "الرئيسية" :
                            <Convert text={"Home"} language={selectedLanguage} />
                            }
                        </span>
                    </NavLink>
                </li>
                <li id='menu-nav' className='menu-nav'>
                    <Link to="/menu">
                    <div className="icon-nav">
                        <svg className='normal' xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                            <path d="M15.1639 3.92748L12.8667 13.8098C12.7031 14.5475 12.0896 15.0588 11.3807 15.0588H2.42356C1.39424 15.0588 0.658044 13.9777 0.964793 12.9186L3.83461 3.04373C4.0323 2.35715 4.62536 1.88232 5.2934 1.88232H13.6779C14.3255 1.88232 14.864 2.30596 15.089 2.89029C15.2185 3.20436 15.2457 3.56228 15.1639 3.92748Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10"/>
                            <path d="M11.6318 15.0588H14.6887C15.5136 15.0588 16.1595 14.2894 16.102 13.3788L15.4688 3.76465" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M7.1582 3.76469L8.05294 0.941162" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M11.6318 3.76469L12.5266 0.941162" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M5.36816 7.5293H11.6313" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M5.36816 10.353H10.7366" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <svg className='onhover' xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                            <path d="M15.1639 3.92748L12.8667 13.8098C12.7031 14.5475 12.0896 15.0588 11.3807 15.0588H2.42356C1.39424 15.0588 0.658044 13.9777 0.964793 12.9186L3.83461 3.04373C4.0323 2.35715 4.62536 1.88232 5.2934 1.88232H13.6779C14.3255 1.88232 14.864 2.30596 15.089 2.89029C15.2185 3.20436 15.2457 3.56228 15.1639 3.92748Z" fill="url(#paint0_linear_2_541)" stroke="url(#paint1_linear_2_541)" strokeWidth="1.5" strokeMiterlimit="10"/>
                            <path d="M11.6318 15.0588H14.6887C15.5136 15.0588 16.1595 14.2894 16.102 13.3788L15.4688 3.76465" stroke="url(#paint2_linear_2_541)" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M7.1582 3.76469L8.05294 0.941162" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M11.6318 3.76469L12.5266 0.941162" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M5.36816 7.5293H11.6313" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M5.36816 10.353H10.7366" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                            <defs>
                            <linearGradient id="paint0_linear_2_541" x1="8.05243" y1="1.88232" x2="8.05243" y2="15.0588" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CA9D4A"/>
                            <stop offset="1" stopColor="#E0DA97"/>
                            </linearGradient>
                            <linearGradient id="paint1_linear_2_541" x1="8.05243" y1="1.88232" x2="8.05243" y2="15.0588" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CA9D4A"/>
                            <stop offset="1" stopColor="#E0DA97"/>
                            </linearGradient>
                            <linearGradient id="paint2_linear_2_541" x1="13.8687" y1="3.76465" x2="13.8687" y2="15.0588" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CA9D4A"/>
                            <stop offset="1" stopColor="#E0DA97"/>
                            </linearGradient>
                            </defs>
                        </svg>
                    </div>
                        <span>
                            { selectedLanguage.toLowerCase() === "en" ? "Menu" :
                                <Convert text={"Menu"} language={selectedLanguage} />
                            }
                            </span>
                        </Link>
                </li>
            </ul>
            <div className="circle-icon2">
                <div className="left-side">
                <svg xmlns="http://www.w3.org/2000/svg" width="79" height="136" viewBox="0 0 79 136" fill="none">
                    <g filter="url(#filter0_dd_2_451)">
                        <path d="M43.2273 8.23684C41.4498 6.12359 38.2854 5.84227 36.2681 7.72803C20.6112 22.3642 10.3005 41.8887 7.08642 63.177C3.87233 84.4653 7.95925 106.164 18.5972 124.77C19.9679 127.167 23.0743 127.832 25.3965 126.338L60.8449 103.528C63.1671 102.034 63.8091 98.9497 62.5926 96.4706C58.7319 88.6026 57.3351 79.7064 58.6552 70.9628C59.9753 62.2192 63.9362 54.1319 69.9478 47.7544C71.8419 45.745 72.139 42.6089 70.3615 40.4957L43.2273 8.23684Z" fill="url(#paint0_linear_2_451)"/>
                    </g>
                    <defs>
                        <filter id="filter0_dd_2_451" x="0.0170898" y="0.468201" width="78.5273" height="134.664" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dx="3" dy="4"/>
                        <feGaussianBlur stdDeviation="2"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_451"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dx="-2" dy="-2"/>
                        <feGaussianBlur stdDeviation="2"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0.695833 0 0 0 0 0.695833 0 0 0 0 0.663941 0 0 0 0.15 0"/>
                        <feBlend mode="normal" in2="effect1_dropShadow_2_451" result="effect2_dropShadow_2_451"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2_451" result="shape"/>
                        </filter>
                        <linearGradient id="paint0_linear_2_451" x1="174.428" y1="16.0088" x2="28.4105" y2="138.83" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#A4A4A4"/>
                        <stop offset="1" stopColor="#616161"/>
                        </linearGradient>
                    </defs>
                </svg>
                    <p>
                        { selectedLanguage.toLowerCase() === "en" ? "Leave the table alone" :
                            <Convert text={"Leave the table alone"} language={selectedLanguage} />
                        }
                    </p>
                </div>
                <div className="top-side">
                <svg xmlns="http://www.w3.org/2000/svg" width="136" height="76" viewBox="0 0 136 76" fill="none">
                    <g filter="url(#filter0_dd_2_448)">
                        <mask id="path-1-inside-1_2_448" fill="white">
                        <path d="M7.93442 32.5981C6.14595 30.4941 6.39441 27.3269 8.58918 25.6511C25.6237 12.6441 46.5901 5.72138 68.1166 6.08967C89.643 6.45797 110.36 14.0939 126.94 27.6759C129.076 29.4259 129.216 32.5997 127.357 34.6413L98.9728 65.8062C97.1134 67.8478 93.9656 67.9684 91.7232 66.3569C84.6062 61.2423 76.0658 58.3865 67.2244 58.2353C58.383 58.084 49.7499 60.6459 42.4621 65.514C40.1658 67.0479 37.024 66.8197 35.2356 64.7157L7.93442 32.5981Z"/>
                        </mask>
                        <path d="M7.93442 32.5981C6.14595 30.4941 6.39441 27.3269 8.58918 25.6511C25.6237 12.6441 46.5901 5.72138 68.1166 6.08967C89.643 6.45797 110.36 14.0939 126.94 27.6759C129.076 29.4259 129.216 32.5997 127.357 34.6413L98.9728 65.8062C97.1134 67.8478 93.9656 67.9684 91.7232 66.3569C84.6062 61.2423 76.0658 58.3865 67.2244 58.2353C58.383 58.084 49.7499 60.6459 42.4621 65.514C40.1658 67.0479 37.024 66.8197 35.2356 64.7157L7.93442 32.5981Z" fill="black"/>
                        <path d="M7.93442 32.5981C6.14595 30.4941 6.39441 27.3269 8.58918 25.6511C25.6237 12.6441 46.5901 5.72138 68.1166 6.08967C89.643 6.45797 110.36 14.0939 126.94 27.6759C129.076 29.4259 129.216 32.5997 127.357 34.6413L98.9728 65.8062C97.1134 67.8478 93.9656 67.9684 91.7232 66.3569C84.6062 61.2423 76.0658 58.3865 67.2244 58.2353C58.383 58.084 49.7499 60.6459 42.4621 65.514C40.1658 67.0479 37.024 66.8197 35.2356 64.7157L7.93442 32.5981Z" stroke="#CDA34F" strokeOpacity="0.64" strokeWidth="4" mask="url(#path-1-inside-1_2_448)"/>
                    </g>
                    <defs>
                        <filter id="filter0_dd_2_448" x="0.748901" y="0.0756836" width="134.905" height="75.3873" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dx="3" dy="4"/>
                        <feGaussianBlur stdDeviation="2"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_448"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dx="-2" dy="-2"/>
                        <feGaussianBlur stdDeviation="2"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0.695833 0 0 0 0 0.695833 0 0 0 0 0.663941 0 0 0 0.15 0"/>
                        <feBlend mode="normal" in2="effect1_dropShadow_2_448" result="effect2_dropShadow_2_448"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2_448" result="shape"/>
                        </filter>
                    </defs>
                </svg>
                <p>
                    {
                        selectedLanguage.toLowerCase() === "en" ? "Need& question" :
                        <Convert text={"Need And Question"} language={selectedLanguage}/>
                    }
                </p>
                </div>
                <div className="right-side" onClick={() => setActivated(true)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="79" height="136" viewBox="0 0 79 136" fill="none">
                    <g filter="url(#filter0_dd_2_458)">
                        <path d="M36.3507 8.07796C38.196 6.02371 41.368 5.84555 43.3228 7.79598C58.4949 22.9341 68.1645 42.7839 70.6839 64.1656C73.2032 85.5472 68.4121 107.101 57.174 125.351C55.7261 127.702 52.5996 128.266 50.3273 126.697L15.6402 102.745C13.3679 101.176 12.8267 98.0727 14.1232 95.6345C18.2381 87.8964 19.9237 79.0504 18.8889 70.2684C17.8542 61.4864 14.1586 53.2745 8.3579 46.7047C6.53019 44.6347 6.33534 41.4906 8.18073 39.4364L36.3507 8.07796Z" fill="url(#paint0_linear_2_458)"/>
                    </g>
                    <defs>
                        <filter id="filter0_dd_2_458" x="0.888374" y="0.430237" width="77.4511" height="135.151" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dx="3" dy="4"/>
                        <feGaussianBlur stdDeviation="2"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_458"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dx="-2" dy="-2"/>
                        <feGaussianBlur stdDeviation="2"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0.695833 0 0 0 0 0.695833 0 0 0 0 0.663941 0 0 0 0.15 0"/>
                        <feBlend mode="normal" in2="effect1_dropShadow_2_458" result="effect2_dropShadow_2_458"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2_458" result="shape"/>
                        </filter>
                        <linearGradient id="paint0_linear_2_458" x1="-90.0357" y1="16.0644" x2="46.9082" y2="139.084" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#CDA34F"/>
                        <stop offset="1" stopColor="#A77C27"/>
                        </linearGradient>
                    </defs>
                </svg>
                <p> {  selectedLanguage.toLowerCase() === "en" ? "My bill" :
                    <Convert text={"My bill"} language={selectedLanguage} />
                    }
                    </p>
                </div>
                <span>
                    {  selectedLanguage.toLowerCase() === "en" ? "Help" : selectedLanguage.toLowerCase() === "ar" ?  "مساعدة" :
                        <Convert text={"Help"} language={selectedLanguage} />
                    }
                </span>
                </div>
            <div className="circle-icon1"></div>
            <ul className="list">
                <li id='my-order-nav' className='my-order-nav'>
                    <Link to="/my-order">
                    <div className="icon-nav">
                    <div className="meal">
                    <svg className='normal' xmlns="http://www.w3.org/2000/svg" width="19" height="15" viewBox="0 0 19 15" fill="none">
                        <path opacity="0.985" d="M10.7857 2.6529L10.4155 2.71696L10.3741 3.09039C10.3191 3.58496 10.3386 4.07526 10.433 4.55958L10.4967 4.8866L10.823 4.95369C14.322 5.67305 16.6413 7.69263 17.8325 11.0567C18.1336 12.0044 18.2331 12.9677 18.1319 13.9508C18.0752 14.1017 18.0011 14.2022 17.9193 14.2714C17.835 14.3428 17.7185 14.4011 17.5511 14.432C12.1605 14.4667 6.76983 14.4668 1.37922 14.4322C1.10302 14.3381 0.947711 14.2103 0.859804 14.0763C0.764706 13.9312 0.705999 13.714 0.742178 13.3766L0.743518 13.3641L0.744229 13.3516C0.916431 10.3085 2.2392 7.94871 4.73374 6.22983C5.79257 5.59997 6.9291 5.16635 8.14574 4.92826L8.47051 4.86471L8.53892 4.54092C8.64106 4.05742 8.67952 3.56602 8.65456 3.06782L8.63188 2.61537L8.17941 2.59343C7.91388 2.58055 7.74378 2.50976 7.62976 2.41668C7.51937 2.32657 7.4193 2.18107 7.354 1.93878C7.31514 1.65708 7.35668 1.46626 7.43195 1.3317C7.5012 1.20788 7.62516 1.08491 7.84584 0.979546C8.19449 0.968449 8.53625 0.943095 8.87103 0.903342C9.62285 0.902494 10.374 0.926123 11.1243 0.974228C11.3451 1.10252 11.4712 1.24305 11.5404 1.38357C11.614 1.53317 11.6472 1.73175 11.6007 2.00631C11.4479 2.38039 11.1925 2.58252 10.7857 2.6529ZM11.6012 6.76005L11.4804 6.7684L11.3768 6.83109C11.1848 6.94727 11.0168 7.11607 10.9292 7.34983C10.8423 7.58152 10.8564 7.81812 10.9181 8.03096L10.9684 8.2041L11.1175 8.30536C11.5249 8.58188 11.9278 8.86465 12.3263 9.15365C13.4568 10.1383 14.1218 11.3685 14.3309 12.8652L14.3579 13.0586L14.509 13.1825C14.7366 13.3692 15.0911 13.5813 15.4862 13.4538C15.8781 13.3274 16.0453 12.9512 16.1249 12.6673L16.1536 12.5648L16.1381 12.4595C15.7689 9.9499 14.4626 8.0653 12.2456 6.84703L12.1815 6.8118L12.11 6.79641C11.9422 6.76031 11.7721 6.74823 11.6012 6.76005Z" stroke="white"/>
                    </svg>
                    <svg className='normal' xmlns="http://www.w3.org/2000/svg" width="23" height="2" viewBox="0 0 23 2" fill="none">
                        <path opacity="0.983" d="M1.87433 0.5461C2.44943 0.540649 3.0161 0.525907 3.57432 0.501857C9.33757 0.496912 15.1008 0.501847 20.864 0.516634C21.0956 0.562899 21.2665 0.615174 21.3864 0.667834C21.4524 0.696816 21.4938 0.72192 21.5187 0.739752C21.5053 0.765179 21.4822 0.802168 21.4429 0.850952C21.3677 0.899014 21.2642 0.943573 21.1225 0.976374C14.7244 0.996023 8.32622 0.996022 1.92808 0.976368C1.62549 0.910785 1.48918 0.830559 1.44055 0.784257C1.43437 0.778373 1.43073 0.774135 1.42872 0.77149C1.42868 0.768275 1.42888 0.76308 1.42993 0.755606C1.45739 0.710325 1.55108 0.613429 1.87433 0.5461ZM1.42903 0.775577C1.42901 0.775585 1.42895 0.775272 1.42888 0.774614L1.42903 0.775577Z" fill="white" stroke="white"/>
                    </svg>
                    </div>
                    <div className="meal">
                    <svg className='onhover' xmlns="http://www.w3.org/2000/svg" width="19" height="15" viewBox="0 0 19 15" fill="none">
                        <path opacity="0.985" d="M10.7857 2.6529L10.4155 2.71696L10.3741 3.09039C10.3191 3.58496 10.3386 4.07526 10.433 4.55958L10.4967 4.8866L10.823 4.95369C14.322 5.67305 16.6413 7.69263 17.8325 11.0567C18.1336 12.0044 18.2331 12.9677 18.1319 13.9508C18.0752 14.1017 18.0011 14.2022 17.9193 14.2714C17.835 14.3428 17.7185 14.4011 17.5511 14.432C12.1605 14.4667 6.76983 14.4668 1.37922 14.4322C1.10302 14.3381 0.947711 14.2103 0.859804 14.0763C0.764706 13.9312 0.705999 13.714 0.742178 13.3766L0.743518 13.3641L0.744229 13.3516C0.916431 10.3085 2.2392 7.94871 4.73374 6.22983C5.79257 5.59997 6.9291 5.16635 8.14574 4.92826L8.47051 4.86471L8.53892 4.54092C8.64106 4.05742 8.67952 3.56602 8.65456 3.06782L8.63188 2.61537L8.17941 2.59343C7.91388 2.58055 7.74378 2.50976 7.62976 2.41668C7.51937 2.32657 7.4193 2.18107 7.354 1.93878C7.31514 1.65708 7.35668 1.46626 7.43195 1.3317C7.5012 1.20788 7.62516 1.08491 7.84584 0.979546C8.19449 0.968449 8.53625 0.943095 8.87103 0.903342C9.62285 0.902494 10.374 0.926123 11.1243 0.974228C11.3451 1.10252 11.4712 1.24305 11.5404 1.38357C11.614 1.53317 11.6472 1.73175 11.6007 2.00631C11.4479 2.38039 11.1925 2.58252 10.7857 2.6529ZM12.2456 6.84703L12.1815 6.8118L12.11 6.79641C11.9422 6.76031 11.7721 6.74823 11.6012 6.76005L11.4804 6.7684L11.3768 6.83109C11.1848 6.94727 11.0168 7.11607 10.9292 7.34983C10.8423 7.58152 10.8564 7.81812 10.9181 8.03096L10.9684 8.2041L11.1175 8.30536C11.5249 8.58188 11.9278 8.86465 12.3263 9.15365C13.4568 10.1383 14.1218 11.3685 14.3309 12.8652L14.3579 13.0586L14.509 13.1825C14.7366 13.3692 15.0911 13.5813 15.4862 13.4538C15.8781 13.3274 16.0453 12.9512 16.1249 12.6673L16.1536 12.5648L16.1381 12.4595C15.7689 9.9499 14.4626 8.0653 12.2456 6.84703Z" fill="url(#paint0_linear_2_925)" stroke="url(#paint1_linear_2_925)"/>
                        <defs>
                        <linearGradient id="paint0_linear_2_925" x1="9.45082" y1="0.40332" x2="9.45082" y2="14.9581" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#CA9D4A"/>
                        <stop offset="1" stopColor="#E0DA97"/>
                        </linearGradient>
                        <linearGradient id="paint1_linear_2_925" x1="9.45082" y1="0.40332" x2="9.45082" y2="14.9581" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#CA9D4A"/>
                        <stop offset="1" stopColor="#E0DA97"/>
                        </linearGradient>
                        </defs>
                    </svg>
                    <svg className='onhover' xmlns="http://www.w3.org/2000/svg" width="23" height="2" viewBox="0 0 23 2" fill="none">
                        <path opacity="0.983" d="M1.87433 0.5461C2.44943 0.540649 3.0161 0.525907 3.57432 0.501857C9.33757 0.496912 15.1008 0.501847 20.864 0.516634C21.0956 0.562899 21.2665 0.615174 21.3864 0.667834C21.4524 0.696816 21.4938 0.72192 21.5187 0.739752C21.5053 0.765179 21.4822 0.802168 21.4429 0.850952C21.3677 0.899014 21.2642 0.943573 21.1225 0.976374C14.7244 0.996023 8.32622 0.996022 1.92808 0.976368C1.62549 0.910785 1.48918 0.830559 1.44055 0.784257C1.43437 0.778373 1.43073 0.774135 1.42872 0.77149C1.42868 0.768275 1.42888 0.76308 1.42993 0.755606C1.45739 0.710325 1.55108 0.613429 1.87433 0.5461ZM1.42903 0.775577C1.42901 0.775585 1.42895 0.775272 1.42888 0.774614L1.42903 0.775577Z" fill="url(#paint0_linear_2_927)" stroke="url(#paint1_linear_2_927)"/>
                        <defs>
                        <linearGradient id="paint0_linear_2_927" x1="11.4819" y1="0" x2="11.4819" y2="1.49111" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#CA9D4A"/>
                        <stop offset="1" stopColor="#E0DA97"/>
                        </linearGradient>
                        <linearGradient id="paint1_linear_2_927" x1="11.4819" y1="0" x2="11.4819" y2="1.49111" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#CA9D4A"/>
                        <stop offset="1" stopColor="#E0DA97"/>
                        </linearGradient>
                        </defs>
                    </svg>
                    </div>
                    </div>
                    <span>
                        {  selectedLanguage.toLowerCase() === "en" ? "My order" :
                            <Convert text={"My order"} language={selectedLanguage} />
                        }
                        </span>
                    </Link>
                </li>
                <li id='about-nav' className='about-nav'>
                    <Link to="/about">
                    <div className="icon-nav">
                    <svg className='normal' xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M8.16667 0C3.65636 0 0 3.65631 0 8.16667C0 12.677 3.65636 16.3333 8.16667 16.3333C12.677 16.3333 16.3333 12.677 16.3333 8.16667C16.3333 3.65631 12.677 0 8.16667 0ZM8.16667 14.7C4.5642 14.7 1.63333 11.7691 1.63333 8.16667C1.63333 4.5642 4.5642 1.63333 8.16667 1.63333C11.7692 1.63333 14.7 4.5642 14.7 8.16667C14.7 11.7691 11.7692 14.7 8.16667 14.7ZM9.18929 4.9C9.18929 5.49212 8.75818 5.92084 8.17497 5.92084C7.56816 5.92084 7.14763 5.49212 7.14763 4.88868C7.14763 4.3087 7.57955 3.87917 8.17497 3.87917C8.75818 3.87917 9.18929 4.3087 9.18929 4.9ZM7.35179 7.35H8.98513V12.25H7.35179V7.35Z" fill="white"/>
                    </svg>
                    <svg className='onhover' xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M8.16667 14.7C4.5642 14.7 1.63333 11.7691 1.63333 8.16667C1.63333 4.5642 4.5642 1.63333 8.16667 1.63333C11.7692 1.63333 14.7 4.5642 14.7 8.16667C14.7 11.7691 11.7692 14.7 8.16667 14.7ZM8.17497 5.92084C8.75818 5.92084 9.18929 5.49212 9.18929 4.9C9.18929 4.3087 8.75818 3.87917 8.17497 3.87917C7.57955 3.87917 7.14763 4.30869 7.14763 4.88868C7.14763 5.49212 7.56816 5.92084 8.17497 5.92084ZM8.98513 7.35H7.35179V12.25H8.98513V7.35Z" fill="url(#paint0_linear_27_2171)"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M8.16667 0C3.65636 0 0 3.65631 0 8.16667C0 12.677 3.65636 16.3333 8.16667 16.3333C12.677 16.3333 16.3333 12.677 16.3333 8.16667C16.3333 3.65631 12.677 0 8.16667 0ZM8.16667 14.7C4.5642 14.7 1.63333 11.7691 1.63333 8.16667C1.63333 4.5642 4.5642 1.63333 8.16667 1.63333C11.7692 1.63333 14.7 4.5642 14.7 8.16667C14.7 11.7691 11.7692 14.7 8.16667 14.7Z" fill="url(#paint1_linear_27_2171)"/>
                        <defs>
                        <linearGradient id="paint0_linear_27_2171" x1="8.16667" y1="0" x2="8.16667" y2="16.3333" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#CA9D4A"/>
                        <stop offset="1" stopColor="#E0DA97"/>
                        </linearGradient>
                        <linearGradient id="paint1_linear_27_2171" x1="8.16667" y1="0" x2="8.16667" y2="16.3333" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#CA9D4A"/>
                        <stop offset="1" stopColor="#E0DA97"/>
                        </linearGradient>
                        </defs>
                    </svg>
                    </div>
                    <span className=''> {  selectedLanguage.toLowerCase() === "en" ? "About" : selectedLanguage.toLowerCase() === "ar" ? "حول المطعم" :
                        <Convert text={"About"} language={selectedLanguage} />
                    }
                        </span>
                    </Link>
                </li>
            </ul>
        </div>
        <div className={activated ? "pay-method" : "pay-method d-none"}>
            <div className="cash-list">
                <div className="exit-note cash" onClick={()=> {setActivated(false);}}>
                        <span className="sp1"></span>
                        <span className="sp2"></span>
                </div>
                <h3>{selectedLanguage === "en" ? "Bill":<Convert text={"Bill"} language={selectedLanguage}/>}</h3>
                <p>{selectedLanguage === "en" ? "Choose your payment preference:":<Convert text={"Choose your payment preference:"} language={selectedLanguage}/>} </p>
                <ul className="method-list-1">
                    <li id='submit-bill1'><span className='empty-circle'></span>{billsepatrtlyLoad ? <Spinner animation="border" variant="light" /> : <Convert text={"Pay for Yourself"} language={selectedLanguage}/>}</li>
                    <li onClick={() => {setActivated(false);setActivated1(true)}}><span className='empty-circle'></span><Convert text={"Cover Entire Table"} language={selectedLanguage}/></li>
                </ul>
            </div>
        </div>
        <div className={activated1 ? "pay-method" : "pay-method d-none"}>
            <div className="cash-list" style={{height : "400px"}}>
                <div className="exit-note cash" onClick={()=> {setActivated1(false);}}>
                        <span className="sp1"></span>
                        <span className="sp2"></span>
                </div>
                <h3 style={{fontSize : "18px" , width : "90%" , display : "flex"  , justifyContent : "center" , marginTop : "30px"}}><Convert text={"Select The Payment Method"} language={selectedLanguage}/></h3>
                <p></p>
                <ul className="method-list">
                    <li><span className='empty-circle'></span>Pay Cash</li>
                    <li><span className='empty-circle'></span>Credit Card</li>
                    <li><span className='empty-circle'></span>Use COUPON</li>
                </ul>
                <div className="cash-btns">
                        <button className="cancel"onClick={()=> {setActivated1(false);setActivated(true)}}><Convert text={"Back"} language={selectedLanguage}/></button>
                        <button className="submit submit-bill" onClick={()=> {setActivated(false) ; setLoaderWholeTable(true)}}>{loaderWholeTable ? <Spinner animation="border" variant="light" style={{width : "20px" , height : "20px"}}/> : <Convert text={"Submit"} language={selectedLanguage}/>}</button>
                </div>
            </div>
        </div>
        </>
    )
}
export default Nav;