import React, { useEffect, useState } from "react";
import facebook from '../../imgs/about/Facebook.png'
import insta from '../../imgs/about/Instagram.png'
import youtube from '../../imgs/about/YouTube.png'
import axios from "axios";
import Header from "../../components/header/Header";
import Nav from "../../components/navBar/Nav";
import Convert from "../../components/Convert";
import { useLanguage } from "../../components/selectionLanguageContext";
import './about.css';
import Error from "../error/Error";
import { Carousel } from "react-bootstrap";
import { BaseUrl, GetAbout } from "../../constants/urls";

function About() {
    let token = localStorage.getItem("token")
    const [photos, setPhotos] = useState([]);
    const [data, setData] = useState([]);
    const { selectedLanguage } = useLanguage();

    const fetchAboutData = async () => {
        try {
            const response = await axios.get(GetAbout, {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + token,
                },
            });
            if(response.status === 200) {
                setData(response.data.data)
                setPhotos(response.data.data.images);
            }
        } catch (error) {
            console.error("Error fetching images:", error);
        }
    };
    useEffect(() => {
        fetchAboutData();
    }, []);

    return (
        <>
        {
            token === null ? <Error/> : <>
            <Header />
            <div className={selectedLanguage.toLowerCase() === "ar" ? "page ar" : "page"}>
            <div className="about-container">
            <Carousel>
  {photos.map((photo, index) => (
      <Carousel.Item key={index}>
      <img
        className="d-block w-100"
        src={`${BaseUrl}/${photo.image}`}
        alt={`Slide ${index}`}
        />
    </Carousel.Item>
  ))}
</Carousel>
                <h2>Fenice Palas</h2>
                <p><Convert text={"Restaurantul Fenice imbina cu succes bucatariile de pe coasta mediteraneana, specificul restaurantului putand fi observat de la inceput, prin decorul specific ce contureaza o atmosfera aparte."} language={selectedLanguage}/></p>
                <div className="rest-info">
                <div className="info text-line">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                    <path d="M8.07704 3.96894C8.07704 3.96894 7.85017 3.4375 7.50367 3.4375C7.16267 3.4375 6.98529 3.59494 6.86704 3.70356C6.74879 3.81219 4.77017 5.43812 4.77017 5.43812C4.77017 5.43812 4.19473 5.94069 4.23873 6.886C4.27585 7.83131 4.46079 9.17675 5.42054 11.0509C6.37342 12.9209 8.75835 15.7781 10.2654 16.7826C10.2654 16.7826 11.6617 17.8537 12.961 18.2889C13.3385 18.4078 14.0934 18.5625 14.2694 18.5625C14.4481 18.5625 14.7637 18.5625 15.126 18.2978C15.4945 18.0304 17.5618 16.368 17.5618 16.368C17.5618 16.368 18.0678 15.9108 17.48 15.3794C16.8894 14.8479 15.0964 13.6661 14.7128 13.3554C14.3285 13.0398 13.7812 13.1787 13.5447 13.3925C13.3089 13.6077 12.8875 13.9617 12.8359 14.0064C12.7589 14.0656 12.5479 14.2574 12.3114 14.1618C12.0102 14.0429 10.7755 13.3726 9.63079 11.8064C8.49298 10.2417 8.36785 9.73019 8.19804 9.17744C8.16924 9.09573 8.16884 9.00669 8.19691 8.92472C8.22499 8.84275 8.27988 8.77265 8.35273 8.72575C8.52323 8.6075 9.15092 8.08431 9.15092 8.08431C9.15092 8.08431 9.55723 7.6835 9.38742 7.21119C9.2176 6.73888 8.07704 3.96894 8.07704 3.96894Z" fill="url(#paint0_linear_2_1189)"/>
                    <defs>
                    <linearGradient id="paint0_linear_2_1189" x1="11.0003" y1="3.4375" x2="11.0003" y2="18.5625" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#CA9D4A"/>
                    <stop offset="1" stopColor="#E0DA97"/>
                    </linearGradient>
                    </defs>
                    </svg>
                    <span>{data.tel}</span>
                    </div>
                    <div className="info text-line">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                    <g clipPath="url(#clip0_2_1185)">
                    <path d="M19.9503 15.225V5.77501C19.9503 4.90351 19.2468 4.20001 18.3753 4.20001H3.66484C2.79334 4.20001 2.08984 4.90351 2.08984 5.77501V15.225C2.08984 16.0965 2.79334 16.8 3.66484 16.8H18.3753C19.2468 16.8 19.9503 16.0965 19.9503 15.225ZM18.5748 5.65951C18.9213 6.00601 18.7323 6.36301 18.5433 6.54151L14.2803 10.4475L18.3753 14.7105C18.5013 14.8575 18.5853 15.0885 18.4383 15.246C18.3018 15.414 17.9868 15.4035 17.8503 15.2985L13.2618 11.382L11.0148 13.4295L8.77834 11.382L4.18984 15.2985C4.05334 15.4035 3.73834 15.414 3.60184 15.246C3.45484 15.0885 3.53884 14.8575 3.66484 14.7105L7.75984 10.4475L3.49684 6.54151C3.30784 6.36301 3.11884 6.00601 3.46534 5.65951C3.81184 5.31301 4.16884 5.48101 4.46284 5.73301L11.0148 11.025L17.5773 5.73301C17.8713 5.48101 18.2283 5.31301 18.5748 5.65951Z" fill="url(#paint0_linear_2_1185)"/>
                    </g>
                    <defs>
                    <linearGradient id="paint0_linear_2_1185" x1="11.0201" y1="4.20001" x2="11.0201" y2="16.8" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#CA9D4A"/>
                    <stop offset="1" stopColor="#E0DA97"/>
                    </linearGradient>
                    <clipPath id="clip0_2_1185">
                    <rect width="21" height="21" fill="white"/>
                    </clipPath>
                    </defs>
                </svg>
                <span>{data.email}</span>
                </div>
                <div className="info clock">
                <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37" fill="none">
                    <path d="M11 19V22.4286L14 25" stroke="url(#paint0_linear_2_1191)" strokeWidth="2" strokeLinecap="round"/>
                    <path d="M11.5 31C16.1944 31 20 27.1944 20 22.5C20 17.8056 16.1944 14 11.5 14C6.80558 14 3 17.8056 3 22.5C3 27.1944 6.80558 31 11.5 31Z" stroke="url(#paint1_linear_2_1191)" strokeWidth="2"/>
                    <defs>
                    <linearGradient id="paint0_linear_2_1191" x1="12.5" y1="19" x2="12.5" y2="25" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#CA9D4A"/>
                    <stop offset="1" stopColor="#E0DA97"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear_2_1191" x1="11.5" y1="14" x2="11.5" y2="31" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#CA9D4A"/>
                    <stop offset="1" stopColor="#E0DA97"/>
                    </linearGradient>
                    </defs>
                    </svg>
                    <span>{data.open}</span>
                    </div>
                    <div className="info location">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="21" viewBox="0 0 15 21" fill="none">
                    <path d="M7.5 0C3.35813 0 0 3.52603 0 7.875C0 9.58748 0.534688 11.1589 1.42344 12.4458C1.43938 12.4766 1.44187 12.5111 1.46 12.5406L6.46 20.4156C6.69188 20.7808 7.0825 21 7.5 21C7.9175 21 8.30812 20.7808 8.54 20.4156L13.54 12.5406C13.5584 12.5111 13.5606 12.4766 13.5766 12.4458C14.4653 11.1589 15 9.58748 15 7.875C15 3.52603 11.6419 0 7.5 0ZM7.5 10.5C6.11937 10.5 5 9.32466 5 7.875C5 6.42534 6.11937 5.25 7.5 5.25C8.88062 5.25 10 6.42534 10 7.875C10 9.32466 8.88062 10.5 7.5 10.5Z" fill="url(#paint0_linear_2_1184)"/>
                    <defs>
                    <linearGradient id="paint0_linear_2_1184" x1="7.5" y1="0" x2="7.5" y2="21" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#CA9D4A"/>
                    <stop offset="1" stopColor="#E0DA97"/>
                    </linearGradient>
                    </defs>
                    </svg>
                <span>{data.address}</span>
                </div>
            </div>
            <div className="social">
                <img src={insta} alt="" />
                <img src={youtube} alt="" />
                <img src={facebook} alt="" />
                </div>
                </div>
                <Nav />
        </div>
    </>
    }
    </>
    );
}

export default About;