import { useEffect, useState } from "react";
import './cards-menu-style.css'
import Convert from '../Convert'
import {useLanguage} from '../selectionLanguageContext'
import { useNavigate } from "react-router-dom";
import { BaseUrl } from "../../constants/urls";
function MenuCard ({data , id}) {
    const {selectedLanguage , updateLanguage} = useLanguage()
    const [selectedCurrency , setSelectedCurrency] = useState(localStorage.getItem("selectedCurrency"))
    const [amount , setAmount] = useState(1);
    const [note , setNote] = useState(false);
    const navigate = useNavigate()
    let increament = () => {
        setAmount(amount + 1);
    }
    let decreament = () => {
        if(amount !== 0 )
        setAmount(amount - 1);
    }
    let displayNone = (e) => {
        setAmount(1)
        if(e.target.localName === "path"){
            e.target.classList.add("op-0");
            e.target.classList.remove("op-100");
            e.target.classList.remove("z-in-99");
            e.target.classList.add("z-in-1");
            e.target.parentElement.parentElement.parentElement.children[1].classList.add("op-100");
            e.target.parentElement.parentElement.parentElement.children[1].classList.add("z-in-99");
            e.target.parentElement.parentElement.parentElement.children[1].classList.remove("z-in-1");
            e.target.parentElement.parentElement.parentElement.children[1].classList.remove("op-0");
            e.target.parentElement.parentElement.children[1].classList.add("op-100");
            e.target.parentElement.parentElement.children[1].classList.add("z-in-99");
            e.target.parentElement.parentElement.children[1].classList.remove("op-0");
            e.target.parentElement.parentElement.children[1].classList.remove("z-in-1");
        }
        if(e.target.localName === "svg"){
            e.target.children[0].classList.add("op-0");
            e.target.children[0].classList.add("z-in-1");
            e.target.children[0].classList.remove("op-100");
            e.target.children[0].classList.remove("z-in-99");
            e.target.parentElement.parentElement.children[1].classList.add("op-100");
            e.target.parentElement.parentElement.children[1].classList.add("z-in-99");
            e.target.parentElement.parentElement.children[1].classList.remove("z-in-1");
            e.target.parentElement.parentElement.children[1].classList.remove("op-0");
            e.target.parentElement.children[1].classList.add("op-100");
            e.target.parentElement.children[1].classList.add("z-in-99");
            e.target.parentElement.children[1].classList.remove("op-0");
            e.target.parentElement.children[1].classList.remove("z-in-1");
        }
    }
    let displayBlock = (e) => {
        if(amount === 0 || amount === 1) {
            if(e.target.localName === "path") {
                e.target.parentElement.parentElement.parentElement.classList.add("op-0");
                e.target.parentElement.parentElement.parentElement.classList.add("z-in-1");
                e.target.parentElement.parentElement.parentElement.classList.remove("op-100");
                e.target.parentElement.parentElement.parentElement.classList.remove("z-in-99");
                e.target.parentElement.parentElement.parentElement.parentElement.parentElement.children[1].classList.add("op-0")
                e.target.parentElement.parentElement.parentElement.parentElement.parentElement.children[1].classList.add("z-in-1")
                e.target.parentElement.parentElement.parentElement.parentElement.parentElement.children[1].classList.remove("z-in-99")
                e.target.parentElement.parentElement.parentElement.parentElement.parentElement.children[1].classList.remove("op-100")
                e.target.parentElement.parentElement.parentElement.parentElement.children[0].children[0].classList.remove("op-0")
                e.target.parentElement.parentElement.parentElement.parentElement.children[0].children[0].classList.remove("z-in-1")
                e.target.parentElement.parentElement.parentElement.parentElement.children[0].children[0].classList.add("op-100")
                e.target.parentElement.parentElement.parentElement.parentElement.children[0].children[0].classList.add("z-in-99")
            }
            if(e.target.localName === "svg") {
                e.target.parentElement.parentElement.classList.add("op-0");
                e.target.parentElement.parentElement.classList.add("z-in-1");
                e.target.parentElement.parentElement.classList.remove("op-100");
                e.target.parentElement.parentElement.classList.remove("z-in-99");
                e.target.parentElement.parentElement.parentElement.parentElement.children[1].classList.add("op-0")
                e.target.parentElement.parentElement.parentElement.parentElement.children[1].classList.add("z-in-1")
                e.target.parentElement.parentElement.parentElement.parentElement.children[1].classList.remove("z-in-99")
                e.target.parentElement.parentElement.parentElement.parentElement.children[1].classList.remove("op-100")
                e.target.parentElement.parentElement.parentElement.children[0].children[0].classList.remove("op-0")
                e.target.parentElement.parentElement.parentElement.children[0].children[0].classList.remove("z-in-1")
                e.target.parentElement.parentElement.parentElement.children[0].children[0].classList.add("op-100")
                e.target.parentElement.parentElement.parentElement.children[0].children[0].classList.add("z-in-99")
            }
        }
    }
    useEffect(()=>{

    })
    return(<>
    <div className="arrow-back-full-screen">
    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="14" viewBox="0 0 9 14" fill="none">
        <path d="M8 1L2 7L8 13" stroke="url(#paint0_linear_27_2199)" strokeWidth="2" strokeLinecap="round"/>
        <defs>
        <linearGradient id="paint0_linear_27_2199" x1="8" y1="7" x2="2" y2="7" gradientUnits="userSpaceOnUse">
        <stop stopColor="#CA9D4A"/>
        <stop offset="1" stopColor="#E0DA97"/>
        </linearGradient>
        </defs>
    </svg>
    </div>
            <div className={!note ? "note-writer d-none": "note-writer"}>
                <div className="writer">
                    <div className="exit-note" onClick={()=> setNote(false)}>
                        <span className="sp1"></span>
                        <span className="sp2"></span>
                    </div>
                    <h5><Convert text="Additional Note" language={selectedLanguage} /></h5>   
                    <p><Convert text="You can add notes to make your order special" language={selectedLanguage} /></p>
                    <textarea className="textareaNote" name="note" placeholder={"write your note"}></textarea>
                    <div className="btns-cont">
                        <button className="cancel" onClick={()=> setNote(false)}><Convert text="Cancel" language={selectedLanguage} /></button>
                        <button className="submit" onClick={()=> setNote(false)}><Convert text="Add" language={selectedLanguage} /></button>
                    </div>
                </div>
            </div>
        <div className="card-menu" >
        <div className="card-body" >
            <div className="price">{data.price.toFixed(1)} {selectedCurrency.toLocaleLowerCase() === "usd" ? <span style={{fontWeight : "300" , marginLeft : "3px"}}>&#x24; </span> : selectedCurrency.toLocaleLowerCase() === "eur"  ? <span style={{fontWeight : "300" , marginLeft : "3px"}}>€</span> : <span className="curr-name-label">{`${selectedCurrency.toLocaleUpperCase()}`}</span> }</div>
            <div className="add-note op-0 z-in-1" onClick={()=> setNote(true)}>
            <svg xmlns="http://www.w3.org/2000/" width="15" height="15" viewBox="0 0 15 15" fill="none">
                <g clipPath="url(#clip0_27_1821)">
                <path fillRule="evenodd" clipRule="evenodd" d="M13.125 5.625H9.375V1.875C9.375 0.839062 8.53547 0 7.5 0C6.46453 0 5.625 0.839062 5.625 1.875V5.625H1.875C0.839531 5.625 0 6.46406 0 7.5C0 8.53594 0.839531 9.375 1.875 9.375H5.625V13.125C5.625 14.1609 6.46453 15 7.5 15C8.53547 15 9.375 14.1609 9.375 13.125V9.375H13.125C14.1605 9.375 15 8.53594 15 7.5C15 6.46406 14.1605 5.625 13.125 5.625Z" fill="url(#paint0_linear_27_1821)"/>
                </g>
                <defs>
                <linearGradient id="paint0_linear_27_1821" x1="7.5" y1="0.528169" x2="7.5" y2="15" gradientUnits="userSpaceOnUse">
                <stop stopColor="#CDA34F"/>
                <stop offset="1" stopColor="#A77C27"/>
                </linearGradient>
                <clipPath id="clip0_27_1821">
                <rect width="15" height="15" fill="white"/>
                </clipPath>
                </defs>
            </svg>
            <span><Convert text="Note" language={selectedLanguage} /></span>
            </div>
            <div className="amount">
            <svg onClick={displayNone} className="clicker" xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
                <path d="M25.8869 0.782654C20.9177 0.782654 16.0601 2.25619 11.9284 5.01692C7.79663 7.77765 4.57634 11.7016 2.67472 16.2925C0.773093 20.8834 0.275542 25.9352 1.24498 30.8089C2.21442 35.6826 4.60731 40.1593 8.12105 43.6731C11.6348 47.1868 16.1116 49.5797 20.9853 50.5492C25.859 51.5186 30.9107 51.021 35.5016 49.1194C40.0926 47.2178 44.0165 43.9975 46.7772 39.8658C49.538 35.7341 51.0115 30.8765 51.0115 25.9073C51.0115 22.6079 50.3616 19.3408 49.099 16.2925C47.8364 13.2442 45.9857 10.4745 43.6527 8.14149C41.3196 5.80845 38.5499 3.95778 35.5016 2.69515C32.4534 1.43252 29.1863 0.782654 25.8869 0.782654ZM35.9367 28.4198H28.3993V35.9571C28.3993 36.6235 28.1346 37.2625 27.6634 37.7337C27.1923 38.2049 26.5532 38.4696 25.8869 38.4696C25.2205 38.4696 24.5815 38.2049 24.1103 37.7337C23.6391 37.2625 23.3744 36.6235 23.3744 35.9571V28.4198H15.837C15.1707 28.4198 14.5316 28.155 14.0604 27.6839C13.5892 27.2127 13.3245 26.5736 13.3245 25.9073C13.3245 25.2409 13.5892 24.6019 14.0604 24.1307C14.5316 23.6595 15.1707 23.3948 15.837 23.3948H23.3744V15.8574C23.3744 15.1911 23.6391 14.552 24.1103 14.0809C24.5815 13.6097 25.2205 13.345 25.8869 13.345C26.5532 13.345 27.1923 13.6097 27.6634 14.0809C28.1346 14.552 28.3993 15.1911 28.3993 15.8574V23.3948H35.9367C36.6031 23.3948 37.2421 23.6595 37.7133 24.1307C38.1845 24.6019 38.4492 25.2409 38.4492 25.9073C38.4492 26.5736 38.1845 27.2127 37.7133 27.6839C37.2421 28.155 36.6031 28.4198 35.9367 28.4198Z" fill="url(#paint0_linear_2_569)"/>
                <defs>
                <linearGradient id="paint0_linear_2_569" x1="25.8868" y1="0.782654" x2="25.8868" y2="51.0319" gradientUnits="userSpaceOnUse">
                <stop stopColor="#CA9D4A"/>
                <stop offset="1" stopColor="#E0DA97"/>
                </linearGradient>
                </defs>
            </svg>
            <div className="inc-dec op-0 z-in-1">
                <div className="inc" onClick={increament}>
                <svg id="s1" xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
                    <path d="M15.5002 0C12.4346 0 9.43787 0.909059 6.88891 2.61222C4.33995 4.31538 2.35328 6.73615 1.18012 9.5684C0.00696004 12.4007 -0.299992 15.5172 0.298079 18.5239C0.89615 21.5306 2.37238 24.2924 4.5401 26.4601C6.70781 28.6279 9.46964 30.1041 12.4763 30.7022C15.4831 31.3002 18.5996 30.9933 21.4318 29.8201C24.2641 28.647 26.6849 26.6603 28.388 24.1113C30.0912 21.5624 31.0002 18.5656 31.0002 15.5C31.0002 13.4645 30.5993 11.4489 29.8204 9.5684C29.0414 7.68786 27.8997 5.97915 26.4604 4.53984C25.0211 3.10054 23.3124 1.95881 21.4318 1.17987C19.5513 0.400919 17.5357 0 15.5002 0ZM21.7002 17.05H17.0502V21.7C17.0502 22.1111 16.8869 22.5053 16.5963 22.796C16.3056 23.0867 15.9113 23.25 15.5002 23.25C15.0892 23.25 14.6949 23.0867 14.4042 22.796C14.1136 22.5053 13.9502 22.1111 13.9502 21.7V17.05H9.30025C8.88916 17.05 8.49492 16.8867 8.20423 16.596C7.91355 16.3053 7.75025 15.9111 7.75025 15.5C7.75025 15.0889 7.91355 14.6947 8.20423 14.404C8.49492 14.1133 8.88916 13.95 9.30025 13.95H13.9502V9.3C13.9502 8.88891 14.1136 8.49466 14.4042 8.20398C14.6949 7.9133 15.0892 7.75 15.5002 7.75C15.9113 7.75 16.3056 7.9133 16.5963 8.20398C16.8869 8.49466 17.0502 8.88891 17.0502 9.3V13.95H21.7002C22.1113 13.95 22.5056 14.1133 22.7963 14.404C23.0869 14.6947 23.2502 15.0889 23.2502 15.5C23.2502 15.9111 23.0869 16.3053 22.7963 16.596C22.5056 16.8867 22.1113 17.05 21.7002 17.05Z" fill="url(#paint0_linear_2_581)"/>
                    <defs>
                    <linearGradient id="paint0_linear_2_581" x1="15.5002" y1="0" x2="15.5002" y2="31" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#CA9D4A"/>
                    <stop offset="1" stopColor="#E0DA97"/>
                    </linearGradient>
                    </defs>
                </svg>
                </div>
                <span className="inc-dec-value">{amount}</span>
                <div className="dec" onClick={(e)=>{decreament();displayBlock(e)}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
                    <path d="M15.5002 0C12.4346 0 9.43787 0.909059 6.88891 2.61222C4.33995 4.31538 2.35328 6.73615 1.18012 9.5684C0.00696003 12.4007 -0.299992 15.5172 0.298079 18.5239C0.89615 21.5306 2.37238 24.2924 4.54009 26.4601C6.70781 28.6279 9.46964 30.1041 12.4763 30.7022C15.4831 31.3002 18.5996 30.9933 21.4318 29.8201C24.2641 28.647 26.6849 26.6603 28.388 24.1113C30.0912 21.5624 31.0002 18.5656 31.0002 15.5C31.0002 13.4645 30.5993 11.4489 29.8204 9.5684C29.0414 7.68786 27.8997 5.97915 26.4604 4.53984C25.0211 3.10054 23.3124 1.95881 21.4318 1.17987C19.5513 0.400919 17.5357 0 15.5002 0ZM21.7002 17.05H13.9502L17.0502 21.7C17.0502 22.1111 16.8869 22.5053 16.5963 22.796C16.3056 23.0867 15.9113 23.25 15.5002 23.25C15.0892 23.25 14.6949 23.0867 14.4042 22.796C14.1136 22.5053 13.9502 22.1111 13.9502 21.7V17.05H9.30025C8.88916 17.05 8.49492 16.8867 8.20423 16.596C7.91355 16.3053 7.75025 15.9111 7.75025 15.5C7.75025 15.0889 7.91355 14.6947 8.20423 14.404C8.49492 14.1133 8.88916 13.95 9.30025 13.95H13.9502V9.3C13.9502 8.88891 14.1136 8.49466 14.4042 8.20398C14.6949 7.9133 15.0892 7.75 15.5002 7.75C15.9113 7.75 16.3056 7.9133 16.5963 8.20398C16.8869 8.49466 17.0502 8.88891 17.0502 9.3L13.9502 13.95H21.7002C22.1113 13.95 22.5056 14.1133 22.7963 14.404C23.0869 14.6947 23.2502 15.0889 23.2502 15.5C23.2502 15.9111 23.0869 16.3053 22.7963 16.596C22.5056 16.8867 22.1113 17.05 21.7002 17.05Z" fill="url(#paint0_linear_2_582)"/>
                    <path d="M13.9502 9.3V13.95L17.0502 9.3C17.0502 8.88891 16.8869 8.49466 16.5963 8.20398C16.3056 7.9133 15.9113 7.75 15.5002 7.75C15.0892 7.75 14.6949 7.9133 14.4042 8.20398C14.1136 8.49466 13.9502 8.88891 13.9502 9.3Z" fill="url(#paint1_linear_2_582)"/>
                    <path d="M17.0502 21.7L13.9502 17.05V21.7C13.9502 22.1111 14.1136 22.5053 14.4042 22.796C14.6949 23.0867 15.0892 23.25 15.5002 23.25C15.9113 23.25 16.3056 23.0867 16.5963 22.796C16.8869 22.5053 17.0502 22.1111 17.0502 21.7Z" fill="url(#paint2_linear_2_582)"/>
                    <defs>
                    <linearGradient id="paint0_linear_2_582" x1="15.5002" y1="0" x2="15.5002" y2="31" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#CA9D4A"/>
                    <stop offset="1" stopColor="#E0DA97"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear_2_582" x1="15.5002" y1="0" x2="15.5002" y2="31" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#CA9D4A"/>
                    <stop offset="1" stopColor="#E0DA97"/>
                    </linearGradient>
                    <linearGradient id="paint2_linear_2_582" x1="15.5002" y1="0" x2="15.5002" y2="31" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#CA9D4A"/>
                    <stop offset="1" stopColor="#E0DA97"/>
                    </linearGradient>
                    </defs>
                </svg>
                </div>
            </div>
            </div>
            <img src={`${BaseUrl}/${data.image}`} alt="" />
            <div className="card-text">
                <h4><Convert text={data.name} language={selectedLanguage} /></h4>
                <p><Convert text={data.description} language={selectedLanguage} /></p>
            </div>
            <div className="card-overlay-menu" onClick={()=> (navigate(`/item/${data.id}`))}>

            </div>
        <div className="hidden-infos">
            <div className="kind">
            <span><Convert text={data.vegetarian ? "Vegetarian" : ""} language={selectedLanguage}/></span>
            <span><Convert text={data.vegan ? "Vegan" : ""} language={selectedLanguage}/></span>
            <span><Convert text={data.lactose_free ? "Lactose Free" : ""} language={selectedLanguage}/></span>
            <span><Convert text={data.gluten_free ? "Gluten Free" : ""} language={selectedLanguage}/></span>
            </div>
            <span className="card-id">{data.id}</span>
            <span className="card-type">item</span>
            <span className="category"><Convert text={data.category} language={selectedLanguage}/></span>
        </div>
        </div>
    </div>
    </>
    )
}


export default MenuCard ;