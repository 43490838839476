import { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import Nav from "../../components/navBar/Nav";
import './myOrder.css'
import MyOrderCard from "../../components/my-order-cards/MyOrderCard";
import { Link, useNavigate } from "react-router-dom";
import Error from "../error/Error";
import axios from "axios";
import Convert from "../../components/Convert";
import { useLanguage } from "../../components/selectionLanguageContext";
import { ItemsPrices, PackagesPrices } from "../../constants/urls";

function MyOrder () {
    // const { myBooleanValue, updateMyBooleanValue } = useMyBoolean();
    const [re , setRe] = useState(0)
    let token = window.localStorage.getItem("token")
    const [selectedCurrency ] = useState(localStorage.getItem("selectedCurrency"))
    const {selectedLanguage} = useLanguage()
    let orderToken = window.localStorage.getItem("token");
    const [submitOrder , setSubmitOrder] = useState(false);
    const [orderIds, setOrderIds] = useState([]);
    useEffect(() => {
        // Load order IDs from local storage on component mount
        const storedOrderIds = JSON.parse(localStorage.getItem('orderIds'));
        if (storedOrderIds) {
          setOrderIds(storedOrderIds);
        }
      }, []);
    // const [note , setNote] = useState(false);
    const navigate = useNavigate();
    // const [getLocalStorageContent , setLocalStorageContent] = useState(window.localStorage);
    let storageArray = [];
    let keys = [];
    let filtersKey = []
    const [showSubmit , setShowSubmit] = useState(false)

    for(let o = 0 ; o < window.localStorage.length ; o++) {
        if(keys.length <= window.localStorage.length){
            keys.push(window.localStorage.key(o))
        }
    }
    filtersKey = keys.filter(filtering)
    function filtering(val) {
        if(val.indexOf("item") === -1 && val.indexOf("pack") === -1){
            
        }
        else {
            return val
        }
    }
    for(let o = 0 ; o < filtersKey.length ; o++) {
        if(storageArray.length <= filtersKey.length){
            storageArray.push(JSON.parse(window.localStorage.getItem(filtersKey[o])))
        }
    }
    const priceEach = [];
    const prices = document.querySelectorAll(".price");
    for(let s = 0 ; s < prices.length ; s++) {
        priceEach.push(parseFloat(prices[s].innerText.replace("$"," ")))
    }
    useEffect (() => {
        const AllCards = document.querySelectorAll(".card-body");
        AllCards.forEach(item => {
        })
        const submitValue = document.querySelectorAll(".submit-value");
        for(let s = 0 ; s < submitValue.length ; s++) {
            submitValue[s].onclick = ()=>{
                submitValue[s].classList.toggle("active-sub");
            }
        }
        let Notes = document.querySelectorAll(".card-menu .add-note")
        let cardPrice = document.querySelectorAll(".card-menu .price");
        let cardTitle = document.querySelectorAll(".card-menu .card-text h4");
        let cardDes = document.querySelectorAll(".card-menu .card-text p");
        let cardAmount = document.querySelectorAll(".card-menu .inc-dec-value");
        let cardImg = document.querySelectorAll(".card-menu img");
        let noteFromMenu = document.querySelectorAll(".note-writer textarea");
        let submitMenu = document.querySelectorAll(".note-writer .submit");
        let increamentBtn = document.querySelectorAll(".inc-dec .inc");
        let decreamentBtn = document.querySelectorAll(".inc-dec .dec");
        let noteCancel = document.querySelectorAll(".note-writer .cancel");
        let noteExit = document.querySelectorAll(".exit-note");
        let cardType = document.querySelectorAll(".card-type");
        let ID = document.querySelectorAll(".card-id");
        const cardMyOrder = {
            id : "" ,
            price : "",
            title : "",
            description : "",
            amount : 1,
            note : "",
            type : "",
        }
        for(let f = 0 ; f < increamentBtn.length ; f ++){
            Notes[f].onclick = () => {
                if(cardType[f].innerText==="item"){
                    noteFromMenu[f].value = JSON.parse(window.localStorage.getItem(`item${ID[f].innerText}`)).note;
                }
                else{
                    noteFromMenu[f].value = JSON.parse(window.localStorage.getItem(`pack${ID[f].innerText}`)).note;
                }
            }
            increamentBtn[f].onclick = ()=> {
                if(cardType[f].innerText==="item"){
                    cardMyOrder.pic = cardImg[f].src;
                    cardMyOrder.title = cardTitle[f].innerText;
                    cardMyOrder.id = ID[f].innerText;
                    cardMyOrder.amount = parseInt(cardAmount[f].innerText)+1;
                    cardMyOrder.description = cardDes[f].innerText;
                    cardMyOrder.price = cardPrice[f].innerText.replace("$","");
                    cardMyOrder.note = noteFromMenu[f].value;
                    cardMyOrder.type = "item";
                    window.localStorage.setItem(`item${ID[f].innerText}`,JSON.stringify(cardMyOrder));
                }else {
                    cardMyOrder.pic = cardImg[f].src;
                    cardMyOrder.title = cardTitle[f].innerText;
                    cardMyOrder.id = ID[f].innerText;
                    cardMyOrder.amount = parseInt(cardAmount[f].innerText)+1;
                    cardMyOrder.description = cardDes[f].innerText;
                    cardMyOrder.price = cardPrice[f].innerText.replace("$","");
                    cardMyOrder.note = noteFromMenu[f].value;
                    window.localStorage.setItem(`pack${ID[f].innerText}`,JSON.stringify(cardMyOrder));
                }
            }
            decreamentBtn[f].onclick = ()=> {
                if(cardAmount[f].innerText != 0 ) {
                    if(cardType[f].innerText==="item"){
                        cardMyOrder.pic = cardImg[f].src;
                        cardMyOrder.title = cardTitle[f].innerText;
                        cardMyOrder.id = ID[f].innerText;
                        cardMyOrder.amount = parseInt(cardAmount[f].innerText) - 1;
                        cardMyOrder.description = cardDes[f].innerText;
                        cardMyOrder.price = cardPrice[f].innerText.replace("$","");
                        cardMyOrder.note = noteFromMenu[f].value;
                        cardMyOrder.type = "item";
                        window.localStorage.setItem(`item${ID[f].innerText}`,JSON.stringify(cardMyOrder));
                    }else {
                        cardMyOrder.pic = cardImg[f].src;
                        cardMyOrder.title = cardTitle[f].innerText;
                        cardMyOrder.id = ID[f].innerText;
                        cardMyOrder.amount = parseInt(cardAmount[f].innerText) - 1;
                        cardMyOrder.description = cardDes[f].innerText;
                        cardMyOrder.price = cardPrice[f].innerText.replace("$","");
                        cardMyOrder.note = noteFromMenu[f].value;
                        cardMyOrder.type = "pack";
                        window.localStorage.setItem(`pack${ID[f].innerText}`,JSON.stringify(cardMyOrder));
                    }
                }
                if(cardAmount[f].innerText == 1) {
                    if(cardType[f].innerText === "item"){
                        // cards[f].classList.add("d-none");
                        window.localStorage.removeItem(`item${ID[f].innerText}`)
                    }
                    if(cardType[f].innerText === "pack"){
                        // cards[f].classList.add("d-none");
                        window.localStorage.removeItem(`pack${ID[f].innerText}`)
                    }
                }
            }   
            submitMenu[f].onclick = ()=> {
                if(cardType[f].innerText==="item"){
                    cardMyOrder.pic = cardImg[f].src;
                    cardMyOrder.title = cardTitle[f].innerText;
                    cardMyOrder.id = ID[f].innerText;
                    cardMyOrder.amount = parseInt(cardAmount[f].innerText)+1;
                    cardMyOrder.description = cardDes[f].innerText;
                    cardMyOrder.price = cardPrice[f].innerText.replace("$","");
                    cardMyOrder.note = noteFromMenu[f].value;
                    cardMyOrder.type = "item";
                    window.localStorage.setItem(`item${ID[f].innerText}`,JSON.stringify(cardMyOrder));
                }else {
                    cardMyOrder.pic = cardImg[f].src;
                    cardMyOrder.title = cardTitle[f].innerText;
                    cardMyOrder.id = ID[f].innerText;
                    cardMyOrder.amount = parseInt(cardAmount[f].innerText)+1;
                    cardMyOrder.description = cardDes[f].innerText;
                    cardMyOrder.price = cardPrice[f].innerText.replace("$","");
                    cardMyOrder.note = noteFromMenu[f].value;
                    cardMyOrder.type = "pack";
                    window.localStorage.setItem(`pack${ID[f].innerText}`,JSON.stringify(cardMyOrder));
                }
            }
            noteCancel[f].onclick = () => {
                if(cardType[f].innerText==="item"){
                    noteFromMenu[f].value = JSON.parse(window.localStorage.getItem(`item${ID[f].innerText}`)).note;
                }
                else{
                    noteFromMenu[f].value = JSON.parse(window.localStorage.getItem(`pack${ID[f].innerText}`)).note;
                }
            }
            noteExit[f].onclick = () => {
                if(cardType[f].innerText==="item"){
                    noteFromMenu[f].value = JSON.parse(window.localStorage.getItem(`item${ID[f].innerText}`)).note;
                }
                else{
                    noteFromMenu[f].value = JSON.parse(window.localStorage.getItem(`pack${ID[f].innerText}`)).note;
                }
            }
            const LASTSUB = document.querySelector("#last-submit");
            const isItLastOrder = document.querySelector("#Together")
            const allComeTogether = document.querySelector("#Last-Order")
            LASTSUB.onclick = async() => {
                let allTog = false;
                let allCom = false;
                if(isItLastOrder.classList.contains("active-sub"))
                    allTog = true;
                if(allComeTogether.classList.contains("active-sub"))
                    allCom = true
                let sendPackArr = []
                let senditemsArr = []
                for(let i = 0 ; i < filtersKey.length ; i++) {
                    if(filtersKey[i].indexOf("pack") === -1){
                        let fromLocal = JSON.parse(window.localStorage.getItem(filtersKey[i]));
                        let item = {
                            id : parseInt(fromLocal.id),
                            quantity : parseInt(fromLocal.amount),
                            notes : String(fromLocal.note)
                        }
                        senditemsArr.push(item)
                    }
                    if(filtersKey[i].indexOf("item") === -1){
                        let fromLocal = JSON.parse(window.localStorage.getItem(filtersKey[i]));
                        let pack = {
                            id : parseInt(fromLocal.id),
                            quantity : parseInt(fromLocal.amount),
                            notes : String(fromLocal.note)
                        }
                        sendPackArr.push(pack)
                    }
                }
                const data = {
                    all_together : allTog,
                    last_order : allCom,
                    items : senditemsArr,
                    packages : sendPackArr
                }
                navigate("/submitting");
                // setSandClock(false)
                // window.location.replace('/submitting');
                await axios.post(ItemsPrices, data , {
                    headers : {
                        Accept: "application/json",
                        Authorization: "Bearer " + token,
                    }
                }).then((res) => {
                    if(res.status === 200){
                        const newOrderId = res.data.data.id;
                        window.location.replace('/order-submitted')
                        // navigate("/order-submitted")
                        localStorage.setItem("BooleanValue",false)
                        for(let i = 0 ; i < filtersKey.length ; i++) {
                            window.localStorage.removeItem(filtersKey[i])
                        }
                        window.localStorage.setItem("order_id",res.data.data.id)
                        setOrderIds((prevOrderIds) => [...prevOrderIds, newOrderId]);
                        localStorage.setItem('orderIds', JSON.stringify([...orderIds, newOrderId]));
                    }})
                    .catch((error) => {
                        // navigate("/my-order")
                    if (error.response) {
                      console.error("Error Status:", error.response.status);
                      console.error("Error Data:", error.response.data);
                    } else {
                      console.error("Error:", error.message);
                    }
                  });
            }
    }
    let idsItms = []
    let idsPackgs = []
    for(let i = 0 ; i < filtersKey.length ; i++) {
        if(filtersKey[i].indexOf("pack") === -1){
            idsItms.push(parseInt(JSON.parse(window.localStorage.getItem(filtersKey[i])).id))
        }
        if(filtersKey[i].indexOf("item") === -1){
            idsPackgs.push(parseInt(JSON.parse(window.localStorage.getItem(filtersKey[i])).id))
        }
    }
    sendIds(idsItms , idsPackgs)
    },[re])
    const [totalPrice , setTotalPrice] = useState(0)
    function calcTotal () {
        let tot = 0;
        let arr = [];
        for(let i = 0 ; i < filtersKey.length ; i++) {
            arr.push(JSON.parse(window.localStorage.getItem(filtersKey[i])))
            }
            arr.forEach(e => {
                try {
                    tot += (parseFloat(e.price * e.amount)) 
                    setTotalPrice(tot)
                }catch(e) {}
        })
    } 
const [itemsPrices , setItemsPrices] = useState([])
const [packagesPrices , setPackagesPrices] = useState([])

    const sendIds = async(ite , pack) => {
        let send = {
            item : ite,
            packages : pack
        }
        console.log(send)
        await axios.post(PackagesPrices,send,{
            headers : {
                Accept: "application/json",
                Authorization: "Bearer " + token,
                currency : selectedCurrency
            }
        }).then(res => {
            if(res.status === 200){
                setRe(1)
                setItemsPrices(res.data.data.items)
                setPackagesPrices(res.data.data.packages)
            }
        }).catch((error) => {
            if (error.response) {
              console.error("Error Status:", error.response.status);
              console.error("Error Data:", error.response.data);
            } else {
              console.error("Error:", error.message);
            }
          })
    }
    useEffect(() => {
            calcTotal() 
    },[itemsPrices , packagesPrices])
    const [clicksNumber , setClicksNumber] = useState(0)
    useEffect (() => {
        const CardsContainer = document.querySelector(".cards-order-container");
        if(CardsContainer.childNodes.length === 0){
            setShowSubmit(false)
        }else {
            setShowSubmit(true)
        }
    },[ clicksNumber])
    useEffect(()=>{
        window.scroll(0, 0);
    },[])
    return(
        <>
            {orderToken === null ? (<Error/>) : ( <>
        <Header/>
                <div className={selectedLanguage.toLowerCase() === "ar" ?  "page ar" : "page"}>
                <div className="total">
            <span>{selectedLanguage.toLowerCase() === "ar" ? "السعر العام" : <Convert text={"My Total"} language={selectedLanguage}/>}</span>
            <span style={{display : "flex" , alignItems : "center"}}>{parseFloat(totalPrice).toFixed(1)} {selectedCurrency.toLocaleLowerCase() === "usd" ? <span style={{fontWeight : "300" , marginLeft : "3px",fontSize : "14px" , color :"black"}}>&#x24; </span> : selectedCurrency.toLocaleLowerCase() === "eur"  ? <span style={{fontWeight : "300" , marginLeft : "3px",fontSize : "14px" , color :"black"}}>€</span> : <span className="curr-name-label-total">{`${selectedCurrency.toLocaleUpperCase()}`}</span> }</span>
        </div>
        <div className="my-order-container">
        <div className="head-order">
            <div className="my-order-btn head-order-btn active-order"><strong>{selectedLanguage.toLowerCase() === "en" ? "My":""}</strong>{selectedLanguage.toLowerCase() === "en" ? " Orders":<Convert text={"My Orders"} language={selectedLanguage}/>}</div>
            <div className="my-table-order-btn head-order-btn"><Link to="/table-order"><strong>{selectedLanguage.toLowerCase() === "en" ? "Table": ""}</strong>{selectedLanguage.toLowerCase() === "en" ? " Orders": selectedLanguage.toLowerCase() === "ar" ? "طلبات الطاولة" : <Convert text={"table's Orders"} language={selectedLanguage}/>}</Link></div>
        </div>
        <div className="my-order-page">
            <div className="cards-order-container" onClick={() => {setClicksNumber(clicksNumber + 1)}}>
                    {storageArray.map((e,i) => {
                        return(
                            <MyOrderCard itemsPrices={itemsPrices} packagesPrices={packagesPrices} calcTotal={calcTotal} data={e} key={i}/>
                            )
                        })}
                </div>
                <div className={showSubmit ? "submit-order" : "submit-order down"} onClick={() => setSubmitOrder(true)}>{selectedLanguage.toLowerCase() === "en" ?  "Submit": selectedLanguage.toLowerCase() === "ar"  ? "تأكيد" : <Convert text={"Submit"} language={selectedLanguage}/>}</div>
        </div>
        </div>
            <div className={!submitOrder ? "submit-order-overlay d-none" : "submit-order-overlay"}>
                <div className="submitting-order">
                <div className="exit-note" onClick={()=> setSubmitOrder(false)}>
                        <span className="sp1"></span>
                        <span className="sp2"></span>
                    </div>
                    <h5><Convert text={"Submitting Order"} language={selectedLanguage}/></h5>
                    <p><Convert text={"Select How Do You Want Your Order "} language={selectedLanguage}/></p>
                    <div className="submit-value" id="Together">
                        <div className="icon-submit">
                            <div className="normal-icon-sub op-100">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <g clipPath="url(#clip0_27_1244)">
                                <path fillRule="evenodd" clipRule="evenodd" d="M0 3.008V13.008C0 13.8293 0.293333 14.5333 0.88 15.12C1.46667 15.7067 2.176 16 3.008 16H13.008C13.8293 15.9893 14.5333 15.696 15.12 15.12C15.7067 14.544 16 13.84 16 13.008V3.008C16 2.176 15.7067 1.46667 15.12 0.88C14.5333 0.293333 13.8293 0 13.008 0H3.008C2.176 0 1.46667 0.293333 0.88 0.88C0.293333 1.46667 0 2.176 0 3.008ZM2.288 13.712C2.096 13.52 2 13.2853 2 13.008V3.008C1.98933 2.74133 2.08533 2.50667 2.288 2.304C2.49067 2.10133 2.73067 2 3.008 2H13.008C13.2853 2.01067 13.52 2.112 13.712 2.304C13.904 2.496 14 2.73067 14 3.008V13.008C14.0107 13.2853 13.9147 13.52 13.712 13.712C13.5093 13.904 13.2747 14 13.008 14H3.008C2.72 14 2.48 13.904 2.288 13.712Z" fill="black" fillOpacity="0.6"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_27_1244">
                                <rect width="16" height="16" fill="white"/>
                                </clipPath>
                                </defs>
                            </svg>
                            </div>
                            <div className="active-icon-sub op-0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <g clipPath="url(#clip0_27_1470)">
                                <path fillRule="evenodd" clipRule="evenodd" d="M0 3.008V13.008C0 13.8293 0.293333 14.5333 0.88 15.12C1.46667 15.7067 2.176 16 3.008 16H13.008C13.8293 15.9893 14.5333 15.696 15.12 15.12C15.7067 14.544 16 13.84 16 13.008V3.008C16 2.176 15.7067 1.46667 15.12 0.88C14.5333 0.293333 13.8293 0 13.008 0H3.008C2.176 0 1.46667 0.293333 0.88 0.88C0.293333 1.46667 0 2.176 0 3.008ZM2.288 13.712C2.096 13.52 2 13.2853 2 13.008V3.008C1.98933 2.74133 2.08533 2.50667 2.288 2.304C2.49067 2.10133 2.73067 2 3.008 2H13.008C13.2853 2.01067 13.52 2.112 13.712 2.304C13.904 2.496 14 2.73067 14 3.008V13.008C14.0107 13.2853 13.9147 13.52 13.712 13.712C13.5093 13.904 13.2747 14 13.008 14H3.008C2.72 14 2.48 13.904 2.288 13.712Z" fill="white"/>
                                <path d="M3.792 8C3.792 7.72267 3.89333 7.488 4.096 7.296C4.29867 7.104 4.53333 7.008 4.8 7.008C5.06667 7.008 5.30133 7.104 5.504 7.296L6.928 8.704L10.464 5.184C10.656 4.98133 10.8907 4.88 11.168 4.88C11.4453 4.88 11.68 4.98133 11.872 5.184C12.064 5.38667 12.1653 5.62133 12.176 5.888C12.1867 6.15467 12.0853 6.38933 11.872 6.592L7.632 10.832C7.44 11.0347 7.20533 11.136 6.928 11.136C6.65067 11.136 6.416 11.0347 6.224 10.832L4.096 8.704C3.89333 8.512 3.792 8.27733 3.792 8Z" fill="white"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_27_1470">
                                <rect width="16" height="16" fill="white"/>
                                </clipPath>
                                </defs>
                                </svg>
                            </div>
                        </div>
                        <div className="option"><Convert text={"All Come Together"} language={selectedLanguage}/></div>
                    </div>
                    <div className="submit-value" id="Last-Order">
                        <div className="icon-submit">
                            <div className="normal-icon-sub op-100">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <g clipPath="url(#clip0_27_1237)">
                                <path fillRule="evenodd" clipRule="evenodd" d="M0 3.008V13.008C0 13.8293 0.293333 14.5333 0.88 15.12C1.46667 15.7067 2.176 16 3.008 16H13.008C13.8293 15.9893 14.5333 15.696 15.12 15.12C15.7067 14.544 16 13.84 16 13.008V3.008C16 2.176 15.7067 1.46667 15.12 0.88C14.5333 0.293333 13.8293 0 13.008 0H3.008C2.176 0 1.46667 0.293333 0.88 0.88C0.293333 1.46667 0 2.176 0 3.008ZM2.288 13.712C2.096 13.52 2 13.2853 2 13.008V3.008C1.98933 2.74133 2.08533 2.50667 2.288 2.304C2.49067 2.10133 2.73067 2 3.008 2H13.008C13.2853 2.01067 13.52 2.112 13.712 2.304C13.904 2.496 14 2.73067 14 3.008V13.008C14.0107 13.2853 13.9147 13.52 13.712 13.712C13.5093 13.904 13.2747 14 13.008 14H3.008C2.72 14 2.48 13.904 2.288 13.712Z" fill="black" fillOpacity="0.6"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_27_1237">
                                <rect width="16" height="16" fill="white"/>
                                </clipPath>
                                </defs>
                            </svg>
                            </div>
                            <div className="active-icon-sub op-0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <g clipPath="url(#clip0_27_1462)">
                                <path fillRule="evenodd" clipRule="evenodd" d="M0 3.008V13.008C0 13.8293 0.293333 14.5333 0.88 15.12C1.46667 15.7067 2.176 16 3.008 16H13.008C13.8293 15.9893 14.5333 15.696 15.12 15.12C15.7067 14.544 16 13.84 16 13.008V3.008C16 2.176 15.7067 1.46667 15.12 0.88C14.5333 0.293333 13.8293 0 13.008 0H3.008C2.176 0 1.46667 0.293333 0.88 0.88C0.293333 1.46667 0 2.176 0 3.008ZM2.288 13.712C2.096 13.52 2 13.2853 2 13.008V3.008C1.98933 2.74133 2.08533 2.50667 2.288 2.304C2.49067 2.10133 2.73067 2 3.008 2H13.008C13.2853 2.01067 13.52 2.112 13.712 2.304C13.904 2.496 14 2.73067 14 3.008V13.008C14.0107 13.2853 13.9147 13.52 13.712 13.712C13.5093 13.904 13.2747 14 13.008 14H3.008C2.72 14 2.48 13.904 2.288 13.712Z" fill="white"/>
                                <path d="M3.792 8C3.792 7.72267 3.89333 7.488 4.096 7.296C4.29867 7.104 4.53333 7.008 4.8 7.008C5.06667 7.008 5.30133 7.104 5.504 7.296L6.928 8.704L10.464 5.184C10.656 4.98133 10.8907 4.88 11.168 4.88C11.4453 4.88 11.68 4.98133 11.872 5.184C12.064 5.38667 12.1653 5.62133 12.176 5.888C12.1867 6.15467 12.0853 6.38933 11.872 6.592L7.632 10.832C7.44 11.0347 7.20533 11.136 6.928 11.136C6.65067 11.136 6.416 11.0347 6.224 10.832L4.096 8.704C3.89333 8.512 3.792 8.27733 3.792 8Z" fill="white"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_27_1462">
                                <rect width="16" height="16" fill="white"/>
                                </clipPath>
                                </defs>
                            </svg>
                            </div>
                        </div>
                        <div className="option"><Convert text={"Is It The Last Order ?"} language={selectedLanguage}/></div>
                    </div>
                    <div className="btns-cont">
                        <button className="cancel" onClick={()=> {setSubmitOrder(false)}} ><Convert text={"Cancel"} language={selectedLanguage}/></button>
                        <button className={"submit"} id="last-submit" onClick={()=> {setSubmitOrder(false);}}><Convert text={"Submit"} language={selectedLanguage}/></button>
                    </div>
                </div>
            </div>
            <Nav />
    </div>
        </>
        ) }
        </>
    )
}
export default MyOrder;