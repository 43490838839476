import  Nav  from "../../components/navBar/Nav"
import Header from "../../components/header/Header"
import Trending from "./Trending"

function FullTrending () {
    return(
        <>
      <div>
        <Header/>
        <Trending />
        <Nav/>
      </div>
        </>
    )
}
export default FullTrending