import React, { createContext, useContext, useState } from 'react';

const MyBooleanContext = createContext();

export const MyBooleanProvider = ({ children }) => {
  const [myBooleanValue, setMyBooleanValue] = useState(true); // Initialize with a default value of false

  const updateMyBooleanValue = (newValue) => {
    setMyBooleanValue(newValue);
  };

  return (
    <MyBooleanContext.Provider
      value={{
        myBooleanValue,
        updateMyBooleanValue,
      }}
    >
      {children}
    </MyBooleanContext.Provider>
  );
};

export const useMyBoolean = () => {
  const context = useContext(MyBooleanContext);
  if (!context) {
    throw new Error('useMyBoolean must be used within a MyBooleanProvider');
  }
  return context;
};