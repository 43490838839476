import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { debounce } from 'lodash';

const Convert = ({ text, language }) => {
  const [convertedText, setConvertedText] = useState('');

  // const translate = debounce(async () => {
  //   try {
  //     const response = await axios.post(
  //       'https://translation.googleapis.com/language/translate/v2',
  //       {},
  //       {
  //         params: {
  //           q: text,
  //           target: language,
  //           key: "AIzaSyBOGdRoIJUy4ael6iAtXchpg7xw8UeaEs4"
  //         }
  //       }
  //     );
  //       setConvertedText(response.data.data.translations[0].translatedText);
  //   } catch (err) {
  //     // console.log('rest api error', err)
  //     // console.log();
  //   }
  // }, 100);

  // useEffect(() => {
  //   // translate();
  // }, [text, language, translate]);

  return text;
};

export default Convert;