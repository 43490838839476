import { useEffect, useState } from "react";
import './my-order-cards-style.css'
import Convert from "../Convert";
import { useLanguage } from "../selectionLanguageContext";
function MyOrderCard ({data , calcTotal , itemsPrices , packagesPrices }) {
    const [selectedCurrency , setSelectedCurrency] = useState(localStorage.getItem("selectedCurrency"))
    const {selectedLanguage , updateLanguage} = useLanguage()
    const [amount , setAmount] = useState(0);
    const [note , setNote] = useState(false);
    useEffect(()=>{
        calcTotal(); // Calculate the total with the updated amount
    },[amount])
    const getPrice = () => {
        if (data.type === "item") {
            // Find the matching item price
            const itemPrice = itemsPrices.find(item => item.id === parseInt(data.id));
            window.localStorage.setItem(`item${data.id}`,JSON.stringify({
                amount : data.amount,
                description : data.description,
                id : data.id,
                note : data.note,
                pic : data.pic,
                price : itemPrice ? itemPrice.price : data.price,
                title : data.title,
                type : data.type
            }))
            return itemPrice ? itemPrice.price : data.price;
        } else if (data.type === "pack") {
            // Find the matching package price
            const packagePrice = packagesPrices.find(packagee => packagee.id === parseInt(data.id));
            window.localStorage.setItem(`pack${data.id}`,JSON.stringify({
                amount : data.amount,
                description : data.description,
                id : data.id,
                note : data.note,
                pic : data.pic,
                price : packagePrice ? packagePrice.offer_price : data.price,
                title : data.title,
                type : data.type
            }))
            return packagePrice ? packagePrice.offer_price : data.price;
        } else {
            return data.price;
        }
    };
    const increament = () => {
        // Use the callback of setState to ensure the state is updated before calling calcTotal
        setAmount((prevAmount) => {
          const newAmount = prevAmount + 1;
          return newAmount; // Return the new state value
        });
      };
      const decreament = () => {
        // Use the callback of setState to ensure the state is updated before calling calcTotal
        setAmount((prevAmount) => {
          const newAmount = prevAmount - 1;
          return newAmount; // Return the new state value
        });
      };
    return(<>
            <div className={!note ? "note-writer d-none": "note-writer"}>
                <div className="writer">
                    <div className="exit-note" onClick={()=> setNote(false)}>
                        <span className="sp1"></span>
                        <span className="sp2"></span>
                    </div>
                    <h5><Convert text={"Add Note"} language={selectedLanguage}/></h5>
                    <p><Convert text={"You can add notes to make your order special "} language={selectedLanguage}/></p>
                    <textarea className="textareaNote" name="note" placeholder="write your note"></textarea>
                    <div className="btns-cont">
                        <button className="cancel" onClick={()=> setNote(false)}><Convert text={"Cancel"} language={selectedLanguage}/></button>
                        <button className="submit" onClick={()=> setNote(false)}><Convert text={"Submit"} language={selectedLanguage}/></button>
                    </div>
                </div>
            </div>
        <div className="card-menu">
        <div className="card-body">
            <div className="price">{typeof getPrice() === "string" ? parseFloat(getPrice().replace("€" || "$" , "")).toFixed(1) : parseFloat(getPrice()).toFixed(1) } {selectedCurrency.toLocaleLowerCase() === "usd" ? <span style={{fontWeight : "300" , marginLeft : "3px"}}>&#x24; </span> : selectedCurrency.toLocaleLowerCase() === "eur"  ? <span style={{fontWeight : "300" , marginLeft : "3px"}}>€</span> : <span className="curr-name-label">{`${selectedCurrency.toLocaleUpperCase()}`}</span> }</div>
            <div className="add-note op-100 z-in-99" onClick={()=> setNote(true)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                <g clipPath="url(#clip0_27_1821)">
                <path fillRule="evenodd" clipRule="evenodd" d="M13.125 5.625H9.375V1.875C9.375 0.839062 8.53547 0 7.5 0C6.46453 0 5.625 0.839062 5.625 1.875V5.625H1.875C0.839531 5.625 0 6.46406 0 7.5C0 8.53594 0.839531 9.375 1.875 9.375H5.625V13.125C5.625 14.1609 6.46453 15 7.5 15C8.53547 15 9.375 14.1609 9.375 13.125V9.375H13.125C14.1605 9.375 15 8.53594 15 7.5C15 6.46406 14.1605 5.625 13.125 5.625Z" fill="url(#paint0_linear_27_1821)"/>
                </g>
                <defs>
                <linearGradient id="paint0_linear_27_1821" x1="7.5" y1="0.528169" x2="7.5" y2="15" gradientUnits="userSpaceOnUse">
                <stop stopColor="#CDA34F"/>
                <stop offset="1" stopColor="#A77C27"/>
                </linearGradient>
                <clipPath id="clip0_27_1821">
                <rect width="15" height="15" fill="white"/>
                </clipPath>
                </defs>
            </svg>
            <span><Convert text={"Note"} language={selectedLanguage}/></span>
            </div>
            <div className="amount">
            <div className="inc-dec op-100 z-in-99">
                <div className="inc" onClick={()=>{increament();}}>
                <svg id="s1" xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
                    <path d="M15.5002 0C12.4346 0 9.43787 0.909059 6.88891 2.61222C4.33995 4.31538 2.35328 6.73615 1.18012 9.5684C0.00696004 12.4007 -0.299992 15.5172 0.298079 18.5239C0.89615 21.5306 2.37238 24.2924 4.5401 26.4601C6.70781 28.6279 9.46964 30.1041 12.4763 30.7022C15.4831 31.3002 18.5996 30.9933 21.4318 29.8201C24.2641 28.647 26.6849 26.6603 28.388 24.1113C30.0912 21.5624 31.0002 18.5656 31.0002 15.5C31.0002 13.4645 30.5993 11.4489 29.8204 9.5684C29.0414 7.68786 27.8997 5.97915 26.4604 4.53984C25.0211 3.10054 23.3124 1.95881 21.4318 1.17987C19.5513 0.400919 17.5357 0 15.5002 0ZM21.7002 17.05H17.0502V21.7C17.0502 22.1111 16.8869 22.5053 16.5963 22.796C16.3056 23.0867 15.9113 23.25 15.5002 23.25C15.0892 23.25 14.6949 23.0867 14.4042 22.796C14.1136 22.5053 13.9502 22.1111 13.9502 21.7V17.05H9.30025C8.88916 17.05 8.49492 16.8867 8.20423 16.596C7.91355 16.3053 7.75025 15.9111 7.75025 15.5C7.75025 15.0889 7.91355 14.6947 8.20423 14.404C8.49492 14.1133 8.88916 13.95 9.30025 13.95H13.9502V9.3C13.9502 8.88891 14.1136 8.49466 14.4042 8.20398C14.6949 7.9133 15.0892 7.75 15.5002 7.75C15.9113 7.75 16.3056 7.9133 16.5963 8.20398C16.8869 8.49466 17.0502 8.88891 17.0502 9.3V13.95H21.7002C22.1113 13.95 22.5056 14.1133 22.7963 14.404C23.0869 14.6947 23.2502 15.0889 23.2502 15.5C23.2502 15.9111 23.0869 16.3053 22.7963 16.596C22.5056 16.8867 22.1113 17.05 21.7002 17.05Z" fill="url(#paint0_linear_2_581)"/>
                    <defs>
                    <linearGradient id="paint0_linear_2_581" x1="15.5002" y1="0" x2="15.5002" y2="31" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#CA9D4A"/>
                    <stop offset="1" stopColor="#E0DA97"/>
                    </linearGradient>
                    </defs>
                </svg>
                </div>
                <span className="inc-dec-value">{parseInt(data.amount)}</span>
                <div className="dec" onClick={(e)=>{decreament()}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
                    <path d="M15.5002 0C12.4346 0 9.43787 0.909059 6.88891 2.61222C4.33995 4.31538 2.35328 6.73615 1.18012 9.5684C0.00696003 12.4007 -0.299992 15.5172 0.298079 18.5239C0.89615 21.5306 2.37238 24.2924 4.54009 26.4601C6.70781 28.6279 9.46964 30.1041 12.4763 30.7022C15.4831 31.3002 18.5996 30.9933 21.4318 29.8201C24.2641 28.647 26.6849 26.6603 28.388 24.1113C30.0912 21.5624 31.0002 18.5656 31.0002 15.5C31.0002 13.4645 30.5993 11.4489 29.8204 9.5684C29.0414 7.68786 27.8997 5.97915 26.4604 4.53984C25.0211 3.10054 23.3124 1.95881 21.4318 1.17987C19.5513 0.400919 17.5357 0 15.5002 0ZM21.7002 17.05H13.9502L17.0502 21.7C17.0502 22.1111 16.8869 22.5053 16.5963 22.796C16.3056 23.0867 15.9113 23.25 15.5002 23.25C15.0892 23.25 14.6949 23.0867 14.4042 22.796C14.1136 22.5053 13.9502 22.1111 13.9502 21.7V17.05H9.30025C8.88916 17.05 8.49492 16.8867 8.20423 16.596C7.91355 16.3053 7.75025 15.9111 7.75025 15.5C7.75025 15.0889 7.91355 14.6947 8.20423 14.404C8.49492 14.1133 8.88916 13.95 9.30025 13.95H13.9502V9.3C13.9502 8.88891 14.1136 8.49466 14.4042 8.20398C14.6949 7.9133 15.0892 7.75 15.5002 7.75C15.9113 7.75 16.3056 7.9133 16.5963 8.20398C16.8869 8.49466 17.0502 8.88891 17.0502 9.3L13.9502 13.95H21.7002C22.1113 13.95 22.5056 14.1133 22.7963 14.404C23.0869 14.6947 23.2502 15.0889 23.2502 15.5C23.2502 15.9111 23.0869 16.3053 22.7963 16.596C22.5056 16.8867 22.1113 17.05 21.7002 17.05Z" fill="url(#paint0_linear_2_582)"/>
                    <path d="M13.9502 9.3V13.95L17.0502 9.3C17.0502 8.88891 16.8869 8.49466 16.5963 8.20398C16.3056 7.9133 15.9113 7.75 15.5002 7.75C15.0892 7.75 14.6949 7.9133 14.4042 8.20398C14.1136 8.49466 13.9502 8.88891 13.9502 9.3Z" fill="url(#paint1_linear_2_582)"/>
                    <path d="M17.0502 21.7L13.9502 17.05V21.7C13.9502 22.1111 14.1136 22.5053 14.4042 22.796C14.6949 23.0867 15.0892 23.25 15.5002 23.25C15.9113 23.25 16.3056 23.0867 16.5963 22.796C16.8869 22.5053 17.0502 22.1111 17.0502 21.7Z" fill="url(#paint2_linear_2_582)"/>
                    <defs>
                    <linearGradient id="paint0_linear_2_582" x1="15.5002" y1="0" x2="15.5002" y2="31" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#CA9D4A"/>
                    <stop offset="1" stopColor="#E0DA97"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear_2_582" x1="15.5002" y1="0" x2="15.5002" y2="31" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#CA9D4A"/>
                    <stop offset="1" stopColor="#E0DA97"/>
                    </linearGradient>
                    <linearGradient id="paint2_linear_2_582" x1="15.5002" y1="0" x2="15.5002" y2="31" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#CA9D4A"/>
                    <stop offset="1" stopColor="#E0DA97"/>
                    </linearGradient>
                    </defs>
                </svg>
                </div>
            </div>
            </div>
            <img src={data.pic} alt="" />
            <div className="card-text">
                <h4><Convert text={data.title} language={selectedLanguage}/></h4>
                <p><Convert text={data.description} language={selectedLanguage}/></p>
            </div>
            <div className="hidden-order">
                <span className="card-type">{data.type}</span>
                <span className="card-id">{data.id}</span>
            </div>
        </div>
    </div>
    </>
    )
}


export default MyOrderCard ;