import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import animationData from './animation_lmg5kt1h.json'; // Import your animation data

function SandClock1() {
  const animationContainer = useRef(null);

  useEffect(() => {
    // Initialize Lottie animation when the component mounts
    const animation = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: 'svg', // You can choose 'svg', 'canvas', or 'html'
      loop: true,
      autoplay: true,
      animationData: animationData, // Your animation data
    });

    // Cleanup function to stop the animation when the component unmounts
    return () => {
      animation.destroy();
    };
  }, []);

  return (
    <div style={{width : "80px" , height : "80px" , position : "absolute" , top : "-21px" , left : "140px"}}>
      <div ref={animationContainer} />
    </div>
  );
}

export default SandClock1;
