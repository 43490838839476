import  Nav  from "../../components/navBar/Nav"
import Header from "../../components/header/Header"
import Packages from "./Packages"
import Error from "../error/Error"

function FullPackages () {
  let token = localStorage.getItem("token")
    return(
        <>
        {
          token === null ? <Error/> :
      <div>
        <Header/>
        <Packages />
        <Nav/>
      </div>
      }
        </>
    )
}
export default FullPackages