import axios from "axios";
let availableLanguages = [];
const googleKey = process.env.GOOGLE_TRANSLATE_KEY;
const fetchSupportedLanguages = async () => {
    try {
      const response = await axios.get(
        'https://translation.googleapis.com/language/translate/v2/languages',
        {
          params: {
            key: googleKey,
          },
        }
      );

        const languageData = response.data.data.languages;
        languageData.map(e => {
            availableLanguages.push(e.language) 
        })
    } catch (err) {
      console.error('Error fetching supported languages:', err);
    }
  };

  fetchSupportedLanguages();

  export default availableLanguages;
