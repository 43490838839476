import  Nav  from "../../components/navBar/Nav"
import Header from "../../components/header/Header"
import Menu from "./Menu"
import { LanguageProvider } from "../../components/selectionLanguageContext";
import Error from "../error/Error";

function FullMenu () {
  let token = localStorage.getItem("token")
    return(
        <>
    <LanguageProvider>
      { token === null ? <Error/> :
        <div>
        <Header/>
        <Menu />
        <Nav/>
      </div>
      }
    </LanguageProvider>
        </>
    )
}
export default FullMenu