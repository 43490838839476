import { useEffect, useState } from 'react';
import { useLanguage } from '../selectionLanguageContext';
import logo from '../../imgs/global/Logo.png';
import './header.css'
import availableLanguages from "../../components/languageData";
function Header(){
    const [ lang , setLang ] = useState("EN");
    const [langVis , setLangVis] = useState(false);
    const {selectedLanguage , updateLanguage} = useLanguage();
    const handleSelectChange = (e) => {
        const newValue = e.target.innerText;
        setLang(newValue)
        updateLanguage(newValue);
        localStorage.setItem('selectedLanguage', newValue);
      };
      useEffect(() => {
        setLang(window.localStorage.getItem("selectedLanguage"))
        const storedLanguafe = localStorage.getItem('selectedLanguage');
        if (storedLanguafe) {
          updateLanguage(storedLanguafe);
        }
      }, [updateLanguage]);
    return(
        <>
        <div className="header">
            <img src={logo} alt="" />
            {/* <div className="language" onClick={()=> {setLangVis(!langVis)}}>
                <div className="selected-lang">{selectedLanguage.toUpperCase()}</div>
                <div className={langVis ? "icon rotate180" :"icon"}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" viewBox="0 0 11 7" fill="none">
                    <path d="M1 1L5.5 5L10 1" stroke="url(#paint0_linear_160_83)" strokeWidth="2" strokeLinecap="round"/>
                    <defs>
                    <linearGradient id="paint0_linear_160_83" x1="5.5" y1="1" x2="5.5" y2="5" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#CA9D4A"/>
                    <stop offset="1" stopColor="#E0DA97"/>
                    </linearGradient>
                    </defs>
                    </svg>
                </div>
                <ul className={langVis ? "lang-list" :"lang-list d-none"}>
                    {
                        availableLanguages.map((e,i) => {
                            return(
                                <li className='la' key={i} onClick={handleSelectChange}>{e.toUpperCase()}</li>
                            )                      
                        })
                    }
                </ul>
            </div> */}
        </div>
        </>
    )
}
export default Header;