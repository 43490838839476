// SelectionContext.js

import React, { createContext, useContext, useState } from 'react';

const SelectionContext = createContext();

export const SelectionProvider = ({ children }) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const [exchangeRate, setExchangeRate] = useState(null); // Add exchangeRate state and setter

  const updateSelection = (newValue) => {
    setSelectedValue(newValue);
  };

  const updateExchangeRate = (newRate) => { // Define updateExchangeRate function
    setExchangeRate(newRate);
  };

  return (
    <SelectionContext.Provider
      value={{
        selectedValue,
        updateSelection,
        exchangeRate, // Make exchangeRate available in context
        updateExchangeRate, // Make updateExchangeRate available in context
      }}
    >
      {children}
    </SelectionContext.Provider>
  );
};

export const useSelection = () => {
  const context = useContext(SelectionContext);
  if (!context) {
    throw new Error('useSelection must be used within a SelectionProvider');
  }
  return context;
};
