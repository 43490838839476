import { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import Nav from "../../components/navBar/Nav";
import axios from "axios";
import Error from "../error/Error";
import TrendingCard from "../../components/trendingCards/TrendingCard";
import './trending.css'
import { Spinner } from "react-bootstrap";
import { useSelection } from "../../components/selectionContext/SelectionContext";
import { GetTrending } from "../../constants/urls";
import svgBadNet from '../../imgs/signal_wifi_bad_FILL0_wght400_GRAD0_opsz24.svg'
import SelectionComponent from "../../components/selectionContext/SelectionComponent";
import { useLanguage } from "../../components/selectionLanguageContext";

function Trending () {
    const { selectedValue } = useSelection();
    const [load , setLoad] = useState(1)
    let trenToken = window.localStorage.getItem("token")
    const [trendingData , setTrendingData] = useState([])
    const [DataRecieved , setDataRecieved] = useState(true)
    const fetchTrending = async ()=> {
        setLoad(1)
        setDataRecieved(true)
        await axios.get(GetTrending,{
            headers : {
                Accept : "application/json",
                Authorization : "Bearer" + trenToken,
                currency : selectedValue
            }
        })
        .then((res) => {
            if(res.status === 200){
                setLoad(0)
                setTrendingData(res.data.data.trending)
            }else {
                setLoad(0)
                setDataRecieved(false)
            }
        }).catch(error =>{
            console.log(error)
            setLoad(0)
            setDataRecieved(false)
        })
    }
    useEffect(() => {
        try {
        let initBtnLocalStorage = document.querySelectorAll(".clicker");
        let addToCart = document.querySelectorAll(".add-note");
        let cardPrice = document.querySelectorAll(".card-menu .price");
        let cardTitle = document.querySelectorAll(".card-menu .card-text h4");
        let cardDes = document.querySelectorAll(".card-menu .card-text p");
        let cardAmount = document.querySelectorAll(".card-menu .inc-dec-value");
        let cardImg = document.querySelectorAll(".card-menu img");
        let noteFromMenu = document.querySelectorAll(".note-writer textarea");
        let submitMenu = document.querySelectorAll(".note-writer .submit");
        let increamentBtn = document.querySelectorAll(".inc-dec .inc");
        let decreamentBtn = document.querySelectorAll(".inc-dec .dec");
        let noteCancel = document.querySelectorAll(".note-writer .cancel");
        let noteExit = document.querySelectorAll(".exit-note");
        let cardType = document.querySelectorAll(".card-type")
        let cardId = document.querySelectorAll(".card-id");
        const nullSearch = document.querySelector(".null");
        //search and filter menu
        const NormalfilterIcon = document.querySelector(".normal-icon");
        const ActivefilterIcon = document.querySelector(".active-icon-search");
        const filterIcon = document.querySelector(".filter-icon");
        const filterList =document.querySelector(".filter-list");
    //add to local
    const cardMyOrder = {
        id: "",
        price : "",
        title : "",
        description : "",
        amount : 1,
        note : "",
        type : "",
        pic : "",
        }
        for(let f =  0 ; f < addToCart.length ; f++) {
            initBtnLocalStorage[f].onclick = () => {
                cardMyOrder.pic = cardImg[f].src;
                cardMyOrder.title = cardTitle[f].innerText;
                cardMyOrder.amount = cardAmount[f].innerText;
                cardMyOrder.description = cardDes[f].innerText;
                cardMyOrder.price = cardPrice[f].innerText.replace("$","");
                cardMyOrder.note = noteFromMenu[f].value;
                cardMyOrder.id = cardId[f].innerText;
                cardMyOrder.type = cardType[f].innerText;
                window.localStorage.setItem(`item${cardId[f].innerText}`,JSON.stringify(cardMyOrder));
            }
            submitMenu[f].onclick = ()=> {
                cardMyOrder.pic = cardImg[f].src;
                cardMyOrder.title = cardTitle[f].innerText;
                cardMyOrder.amount = parseInt(cardAmount[f].innerText);
                cardMyOrder.description = cardDes[f].innerText;
                cardMyOrder.price = cardPrice[f].innerText.replace("$","");
                cardMyOrder.note = noteFromMenu[f].value;
                cardMyOrder.id = cardId[f].innerText;
                cardMyOrder.type = cardType[f].innerText;
                window.localStorage.setItem(`item${cardId[f].innerText}`,JSON.stringify(cardMyOrder));
            }
            increamentBtn[f].onclick = ()=> {
                cardMyOrder.pic = cardImg[f].src;
                cardMyOrder.title = cardTitle[f].innerText;
                cardMyOrder.amount = parseInt(cardAmount[f].innerText)+1;
                cardMyOrder.description = cardDes[f].innerText;
                cardMyOrder.price = cardPrice[f].innerText.replace("$","");
                cardMyOrder.note = noteFromMenu[f].value;
                cardMyOrder.id = cardId[f].innerText;
                cardMyOrder.type = cardType[f].innerText;
                window.localStorage.setItem(`item${cardId[f].innerText}`,JSON.stringify(cardMyOrder));
            }
            decreamentBtn[f].onclick = ()=> {
                if(cardAmount[f].innerText > 1){
                    cardMyOrder.pic = cardImg[f].src;
                    cardMyOrder.title = cardTitle[f].innerText;
                    cardMyOrder.amount = parseInt(cardAmount[f].innerText);
                    cardMyOrder.description = cardDes[f].innerText;
                    cardMyOrder.price = cardPrice[f].innerText.replace("$","");
                    cardMyOrder.note = noteFromMenu[f].value;
                    cardMyOrder.id = cardId[f].innerText;
                    cardMyOrder.type = cardType[f].innerText;
                    window.localStorage.setItem(`item${cardId[f].innerText}`,JSON.stringify(cardMyOrder));
                }else{
                    window.localStorage.removeItem(`item${cardId[f].innerText}`);
                }
            }
        }
    }catch(error) {
        console.log(error);
    }
},[trendingData])
    useEffect(() => {
        fetchTrending()
    },[selectedValue])
    useEffect(()=>{
        window.scroll(0, 0);
    },[])
    const {selectedLanguage , updateLanguage} = useLanguage()
    return(
        <>
        {trenToken === null ? (<Error/>) : ( 
            <>
            <Header/>
            <div className={selectedLanguage.toLowerCase === "ar" ? "page ar" : "page"}>
            {/* <SelectionComponent /> */}
            <div className="cards-menu-container trnding-section position-relative">
                    {load ? (<Spinner animation="border" variant="warning" className="spinner"/>) : DataRecieved ? trendingData.map((item , i) => {
                        return(
                            <TrendingCard data={item} key={i + "A"}/>
                            )
                        }) : <div style={{
                            position : "fixed",
                            color : "white",
                            left : "50%",
                            top : "50%",
                            transform : "translateX(-50%) translateY(-50%)",
                            display : "flex",
                            flexDirection : "column",
                            alignItems : "center"
                        }}>
                            <img style={{width : "70px" , height : "70px"}} src={svgBadNet} alt="" />
                            <span style={{
                                display :"block",
                                margin : "10px 0",
                                fontSize : "22px",
                                fontWeight : "600"
                            }}>Poor Connection</span>
                            <p onClick={()=>{fetchTrending()}} className="bad-network" style={{fontSize : "20px", color : "black" , background : "#CDA34F", padding : "5px 15px" , borderRadius : "10px" , fontWeight : "500" , cursor : "pointer"}}>Retry 
                            <svg style={{marginLeft : "10px"}} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path fill="black" d="M463.5 224H472c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2L413.4 96.6c-87.6-86.5-228.7-86.2-315.8 1c-87.5 87.5-87.5 229.3 0 316.8s229.3 87.5 316.8 0c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0c-62.5 62.5-163.8 62.5-226.3 0s-62.5-163.8 0-226.3c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H463.5z"/></svg>
                            </p>
                            </div> }
                </div>
            <Nav/>
        </div>
        </>
        )}
        </>
    )
}

export default Trending;