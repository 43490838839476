import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import animationData from './animation_lmgbcgxd.json'; // Import your animation data

function Meal() {
  const animationContainer = useRef(null);

  useEffect(() => {
    // Initialize Lottie animation when the component mounts
    const animation = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: 'svg', // You can choose 'svg', 'canvas', or 'html'
      loop: true,
      autoplay: true,
      animationData: animationData, // Your animation data
    });

    // Cleanup function to stop the animation when the component unmounts
    return () => {
      animation.destroy();
    };
  }, []);

  return (
    <div className='meal-tracking' style={{width : "100vw" , left : "50%", transform : "translateX(-50%)"}}>
      <div ref={animationContainer} />
    </div>
  );
}

export default Meal;
