import { useState } from 'react';
import './please-wait.css'
import { useNavigate } from 'react-router-dom';
import Convert from '../../components/Convert';
import { useLanguage } from '../../components/selectionLanguageContext';
function OrderSubmitted() {
    setTimeout(() => {
                window.location.replace('/home');
                localStorage.setItem("BooleanValue",false)
            }, 3000);
            const {selectedLanguage} = useLanguage()
    return(
        <div className="please-wait">
            {/* <img src={bg} alt="" /> */}
            <p>{selectedLanguage.toLowerCase() === "en" ? "Chef's in action! Your order is confirmed" : selectedLanguage.toLowerCase() === "ar" ? "طلبك قيد المعالجة الآن" : <Convert text={"Chef's in action! Your order is confirmed"} language={selectedLanguage} />}</p>
            <div className="wrapper"> 
                <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"> 
                    <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                    <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                </svg>
            </div>
        </div>
    )
}
export default OrderSubmitted;