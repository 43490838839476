// export const BaseUrl = "https://alzero1store.com";
const baseURL = process.env.REACT_APP_BASE_URL;
export const BaseUrl = baseURL;
export const RESERVATION = `${BaseUrl}/api/table/reservation`;
export const ORDERSTATUS = `${BaseUrl}/api/orderStatus`;
export const GetCurrenciesInfo = `${BaseUrl}/api/getInfo`;
export const GetTrending = `${BaseUrl}/api/trending`;
export const GetPackages = `${BaseUrl}/api/packages_edited`;
export const GetTableOrder = `${BaseUrl}/api/order`;
export const PackagesPrices = `${BaseUrl}/api/myOrderPrices`;
export const ItemsPrices = `${BaseUrl}/api/order`;
export const GetCategories = `${BaseUrl}/api/categories`;
export const GetAbout = `${BaseUrl}/api/about`;
export const GetCurrency = `${BaseUrl}/api/currency`;
export const AskForPrivacy = `${BaseUrl}/api/table/privacy`
export const AskForHelp = `${BaseUrl}/api/table/needHelp`
export const AskForBill = `${BaseUrl}/api/table/bill`
export const GetItem = `${BaseUrl}/api/item`
export const GetPackage = `${BaseUrl}/api/package`