import Header from "../../components/header/Header";
import Nav from "../../components/navBar/Nav";
import left from '../../imgs/home/left-side.png';
import right from '../../imgs/home/right-side.png';
import meat from '../../imgs/home/meat-img.png';
import rectangle from '../../imgs/home/Rectangle 8.png';
import fireSvg from '../../imgs/home/fire.svg';
import mailSvg from '../../imgs/home/mail.svg'
import {Link} from "react-router-dom";
import MenuPic from '../../imgs/home/0021-1618x1080 1 (1).png'
import Error from '../error/Error'
import './home.css';
import { useLanguage } from "../../components/selectionLanguageContext";
import Convert from "../../components/Convert";
import SelectionComponent from "../../components/selectionContext/SelectionComponent";
import { useEffect } from "react";
import { useSelection } from "../../components/selectionContext/SelectionContext";
function Home() {
    const {selectedLanguage , updateLanguage} = useLanguage()
    const { selectedValue} = useSelection();
    if(selectedValue === null)
    localStorage.setItem("selectedCurrency" , "USD")
    let tokenHome = window.localStorage.getItem("token");
    useEffect(()=>{
        if(selectedLanguage === "en") {
            updateLanguage('en')
        } 
    },[])
    return(
        <>
        {tokenHome === null ? (<Error/>) : (
            <div className="page">
            <Header/>
            {/* <SelectionComponent /> */}
            <div className={selectedLanguage.toLowerCase()=== "ar" ? "home ar" : "home"}>
            <div className="card-home palas">
                        <h2><strong>{ selectedLanguage.toLowerCase() === "en" ? "Our" : ""} </strong>{ selectedLanguage.toLowerCase() === "en" ? "Menu" :selectedLanguage.toLowerCase() === "ar" ? "قائمة الطعام" : <Convert text={"Our Menu"} language={selectedLanguage}/>}</h2>
                        <div className="imgs-container">
                            <img src={left} alt="" className="left-s" />
                            <img src={right} alt="" className="right-s" />
                            <img src={rectangle} alt="" className="rect" />
                            <img src={MenuPic} alt="" className="meat" />
                            <div className="text-card-home" style={{top : "50px"}}>
                            <svg className="card-svg" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none" style={{
                                position : "absolute",
                                left : "50%",
                                transform : "translateX(-50%)"
                            }}>
                                <g clipPath="url(#clip0_1043_17751)">
                                    <path d="M29.5735 37.5562L22.3397 40.1607C22.2293 40.2008 22.1129 40.2213 21.9976 40.2213C21.8822 40.2213 21.7658 40.2008 21.6554 40.1607L14.1303 37.4515C16.6878 34.7033 19.44 31.9375 21.9976 29.1902C24.5864 31.9717 26.9837 34.7708 29.5735 37.5562ZM43.5669 3.96149C43.298 3.77378 42.9539 3.72587 42.6469 3.8383L21.9976 11.2725L1.34723 3.8383C1.03926 3.72685 0.695123 3.77378 0.427241 3.96149C0.15936 4.15311 0 4.4601 0 4.78762V31.6549C0 32.0802 0.265926 32.4586 0.665793 32.6023L11.6968 36.5746C14.7266 33.753 17.7564 30.9325 20.7862 28.1138C19.831 27.0883 18.8768 26.0597 17.9217 25.0361C15.9047 26.4821 12.917 25.9327 11.12 23.7143C9.18813 21.3728 7.52023 17.1894 9.13436 15.4755C10.8482 13.8604 15.0336 15.5303 17.3732 17.4611C19.5915 19.2591 20.1419 22.2449 18.695 24.2618C19.7333 25.2287 20.9583 26.1624 21.9976 27.1313C22.9713 26.224 23.7534 25.3539 24.7262 24.4495C23.905 23.0466 24.4495 21.0824 25.786 19.961C27.3131 18.6783 32.9787 13.8144 33.1342 13.9689C33.2847 14.1205 29.3115 17.8747 26.6571 20.5369L27.1 20.9788C29.9333 18.4955 33.9359 14.7706 34.0884 14.9221C34.239 15.0747 30.3899 18.9521 27.8235 21.7033L28.2625 22.1432C31.0107 19.5729 34.8862 15.7229 35.0397 15.8734C35.1902 16.0269 31.4673 20.0275 28.983 22.8618L29.422 23.3008C32.0861 20.6464 35.8374 16.6692 35.989 16.8227C36.1415 16.9762 30.0086 24.164 30.0008 24.1748C30.0008 24.1748 31.303 22.6467 29.9968 24.1709C28.7503 25.6306 26.8859 26.0725 25.5093 25.2307C24.6158 26.1908 23.7241 27.1498 22.8296 28.1089C25.8965 30.9647 28.9634 33.8185 32.0324 36.6743L43.3332 32.6052C43.7341 32.4625 44.001 32.0832 44.001 31.6579V4.78762C43.9941 4.4601 43.8358 4.15311 43.5669 3.96149Z" fill="url(#paint0_linear_1043_17751)"/>
                                </g>
                                <defs>
                                    <linearGradient id="paint0_linear_1043_17751" x1="22.0005" y1="5.06146" x2="22.0005" y2="40.2213" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#CDA34F"/>
                                    <stop offset="1" stopColor="#A77C27"/>
                                    </linearGradient>
                                    <clipPath id="clip0_1043_17751">
                                    <rect width="44" height="44" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                            <Link to="/menu"><p className="menu-p" style={{marginTop :  "50px"}}>{ selectedLanguage.toLowerCase() === "en" ? "Our Menu" :<Convert text={"Our Menu"} language={selectedLanguage}/>}</p></Link>
                            </div>
                        </div>
                    </div>
                <div className="cards-homr">
                    <div className="card-home trending">
                        <h2><strong>{selectedLanguage.toLowerCase() === "en" ? "Trended " : ""} </strong>{selectedLanguage.toLowerCase() === "en" ?"Today":selectedLanguage.toLowerCase() === "ar" ? "مأكولات رائجة" : <Convert text={"Trended Today"} language={selectedLanguage}/>}</h2>
                        <div className="imgs-container">
                            <img src={left} alt="" className="left-s" />
                            <img src={right} alt="" className="right-s" />
                            <img src={rectangle} alt="" className="rect" />
                            <img src={meat} alt="" className="meat" />
                            <img className="card-svg" src={fireSvg} alt="" />
                            <div className="text-card-home">
                                <Link to="/trending"><p>{ selectedLanguage.toLowerCase() === "en" ? "Trended Today" :selectedLanguage.toLowerCase() === "ar" ? "رائجة اليوم" : <Convert text={"Trended Today"} language={selectedLanguage}/>}</p></Link>
                            </div>
                        </div>
                    </div>
                    <div className="card-home packages">
                        <h2><strong>{selectedLanguage.toLowerCase() === "en" ? "Packages" :selectedLanguage.toLowerCase() === "ar" ? "عروضنا" : <Convert text={"Packages"} language={selectedLanguage}/> }</strong></h2>
                        <div className="imgs-container">
                            <img src={left} alt="" className="left-s" />
                            <img src={right} alt="" className="right-s" />
                            <img src={rectangle} alt="" className="rect" />
                            <img src={meat} alt="" className="meat" />
                            <img className="card-svg" src={mailSvg} alt="" />
                            <div className="text-card-home">
                                <Link to="/special-offers"><p>{ selectedLanguage.toLowerCase() === "en" ? "Special Offers" :<Convert text={"Special Offers"} language={selectedLanguage}/>}</p></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Nav data={"home-nav"}/>
        </div>
        )}
        </>
    )
}

export default Home;