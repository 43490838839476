import { useState } from 'react';
import './tableCrad.css'
import { BaseUrl } from '../../constants/urls';
function TableCard(data) {
    const [selectedCurrency , setSelectedCurrency] = useState(localStorage.getItem("selectedCurrency"))
    return(<>
    <div className="card-menu table-orders">
    <div className="card-body">
        <div className="price">{data.data.type === "item" ? parseFloat(data.data.price).toFixed(1) : parseFloat(data.data.offer_price).toFixed(1)} {selectedCurrency.toLocaleLowerCase() === "usd" ? "$" : "€" }</div>
        <img src={`${BaseUrl}/${data.data.image}`} alt="" />
        <div className="card-text">
            <h4>{data.data.name}</h4>
        </div>
        <div className="quantity">
        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
            <path d="M19 0C15.2422 0 11.5687 1.11433 8.44417 3.20208C5.31964 5.28982 2.88436 8.25722 1.4463 11.729C0.00823239 15.2008 -0.368031 19.0211 0.365088 22.7067C1.09821 26.3923 2.90778 29.7778 5.56498 32.435C8.22218 35.0922 11.6077 36.9018 15.2933 37.6349C18.9789 38.368 22.7992 37.9918 26.271 36.5537C29.7428 35.1156 32.7102 32.6804 34.7979 29.5558C36.8857 26.4313 38 22.7578 38 19C38 16.5049 37.5086 14.0342 36.5537 11.729C35.5989 9.42383 34.1993 7.32928 32.435 5.56497C30.6707 3.80066 28.5762 2.40113 26.271 1.44629C23.9658 0.491449 21.4951 0 19 0ZM26.6 20.9H17.1L20.9 26.6C20.9 27.1039 20.6998 27.5872 20.3435 27.9435C19.9872 28.2998 19.5039 28.5 19 28.5C18.4961 28.5 18.0128 28.2998 17.6565 27.9435C17.3002 27.5872 17.1 27.1039 17.1 26.6V20.9H11.4C10.8961 20.9 10.4128 20.6998 10.0565 20.3435C9.70018 19.9872 9.50001 19.5039 9.50001 19C9.50001 18.4961 6.91985 21.2563 7.27617 20.9C7.63249 20.5437 10.8961 20.9 11.4 20.9H17.6565L17.1 11.4C17.1 10.8961 17.3002 10.4128 17.6565 10.0565C18.0128 9.70018 18.4961 9.5 19 9.5C19.5039 9.5 19.9872 9.70018 20.3435 10.0565C20.6998 10.4128 20.9 10.8961 20.9 11.4L17.6565 20.9H26.6C27.1039 20.9 28.8152 21.3431 29.1716 21.6994C29.5279 22.0557 28.5 18.4961 28.5 19C28.5 19.5039 28.2998 19.9872 27.9435 20.3435C27.5872 20.6998 27.1039 20.9 26.6 20.9Z" fill="url(#paint0_linear_25_959)"/>
            <path d="M17.1 11.4L17.6565 20.9L20.9 11.4C20.9 10.8961 20.6998 10.4128 20.3435 10.0565C19.9872 9.70018 19.5039 9.5 19 9.5C18.4961 9.5 18.0128 9.70018 17.6565 10.0565C17.3002 10.4128 17.1 10.8961 17.1 11.4Z" fill="url(#paint1_linear_25_959)"/>
            <path d="M20.9 26.6L17.1 20.9V26.6C17.1 27.1039 17.3002 27.5872 17.6565 27.9435C18.0128 28.2998 18.4961 28.5 19 28.5C19.5039 28.5 19.9872 28.2998 20.3435 27.9435C20.6998 27.5872 20.9 27.1039 20.9 26.6Z" fill="url(#paint2_linear_25_959)"/>
            <defs>
            <linearGradient id="paint0_linear_25_959" x1="19" y1="0" x2="19" y2="38" gradientUnits="userSpaceOnUse">
            <stop stopColor="#CA9D4A"/>
            <stop offset="1" stopColor="#E0DA97"/>
            </linearGradient>
            <linearGradient id="paint1_linear_25_959" x1="19" y1="0" x2="19" y2="38" gradientUnits="userSpaceOnUse">
            <stop stopColor="#CA9D4A"/>
            <stop offset="1" stopColor="#E0DA97"/>
            </linearGradient>
            <linearGradient id="paint2_linear_25_959" x1="19" y1="0" x2="19" y2="38" gradientUnits="userSpaceOnUse">
            <stop stopColor="#CA9D4A"/>
            <stop offset="1" stopColor="#E0DA97"/>
            </linearGradient>
            </defs>
        </svg>
        <span>{data.data.quantity}</span>
        </div>
    </div>
</div>
</>
)
}
export default TableCard;